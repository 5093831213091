import React from 'react';
import InputMask from 'react-input-mask';
import {FormFeedback, Input} from 'reactstrap';

export default function({field, form: {touched, errors}, ...props}) {
  return (
    <>
      <InputMask {...field} {...props}>
        {inputProps => (
          <Input {...inputProps} invalid={!!(touched[field.name] && errors[field.name])} onChange={field.onChange} />
        )}
      </InputMask>
      {touched[field.name] && errors[field.name] && <FormFeedback>{errors[field.name]}</FormFeedback>}
    </>
  );
}
