import React, {useCallback, useState} from 'react';
import {Button, Container, Col, Row} from 'reactstrap';
import Modal from 'components/Modal';
import useExitIntent from 'utils/useExitIntent.js';

export default function ModalExitLanding(props) {
  const {isOpen, toggle} = useExitIntent(30000);
  return (
    <Modal className="modal-exit-intent" isOpen={isOpen} toggle={toggle}>
      <Row className="text-center d-flex align-items-stretch m-0">
        <Col xs="12" lg="7" className="modal-exit-intent-copy order-lg-2 my-auto">
          <h3 className="section-heading">Just so you know, there’s a special offer on this page!</h3>
          <p className="my-4">
            If you leave now, you’ll <br className="d-none d-lg-block d-xl-none" />
            never see it again.
          </p>
          <p>
            Learn more about <br className="d-none d-lg-block d-xl-none" />
            Plant-Powered & Thriving <br className="d-none d-sm-block" />
            and what it can do for your health.
          </p>
          <Button className="btn-exit text-uppercase text-white text-h5" color="cta" onClick={toggle} block>
            Check It Out
          </Button>
        </Col>
        <Col xs="12" lg="5" className="exit-modal-image-col order-lg-1">
          <div className="exit-modal-image background-image-girl-carrot-eyes" />
        </Col>
      </Row>
    </Modal>
  );
}
