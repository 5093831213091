import React, {useEffect} from 'react';
import {usePhaseState} from 'funnel-schedule/phase-context';
import SalesIndex from './join';
import track from 'utils/track';

export default function (props) {
  const {
    phases: {
      sales: salesSchedule = {
        phase: 'during',
        salesEnd: ''
      }
    }
  } = usePhaseState();

  useEffect(() => {
    track('Product Viewed', {
      product_id: 'C-PPT',
      sku: 'C-PPT',
      name: 'Plant-Powered & Thriving',
      variant: salesSchedule.phase != 'during' ? 'ppt-wlc-30daytrial' : 'ppt-special-wlc-30daytrial',
      value: salesSchedule.phase != 'during' ? 497 : 297,
      currency: 'usd'
    });
  }, [salesSchedule.phase]);

  return <SalesIndex ctaOverride={salesSchedule.phase === 'during' ? 'sales297' : 'sales497'} version="salesSpecial" />;
}
