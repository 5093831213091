import {useCallback, useEffect, useState} from 'react';

export default function useFixedFloating(el) {
  const [isFixed, setIsFixed] = useState(false);
  const handleScroll = useCallback(
    e => {
      const {bottom, height} = el.current.getBoundingClientRect();
      setIsFixed(bottom - height * 0.25 < 0);
    },
    [el.current]
  );
  useEffect(() => {
    if (typeof window === 'undefined' || typeof document === 'undefined') return;
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const disableListener = () => {
    setIsFixed(false);
    return window.removeEventListener('scroll', handleScroll);
  };

  return [isFixed, disableListener];
}
