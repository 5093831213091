import React, {useEffect, useState} from 'react';
import {Root, Routes, useSiteData} from 'react-static';
import {Router, globalHistory, useLocation} from '@reach/router';
import axios from 'axios';
import queryString from 'query-string';

import {PhaseProvider, usePhaseDispatch} from './funnel-schedule/phase-context';
import {UserProvider, useUserState, useUserDispatch} from './contexts/user';
import {ModalProvider} from 'contexts/modal';
import {page} from 'utils/track';
import 'styles/app.scss';

//Due to a limitation of dotenv we must use three seperate statements
const {SCHEDULE_URL} = process.env;
const {SF_API_URL} = process.env;
const {SITE_ID} = process.env;

function AppWrapper({children}) {
  const phaseDispatch = usePhaseDispatch();
  const userDispatch = useUserDispatch();
  const {registrationId} = useUserState();
  const [lastLocation, setLastLocation] = useState();
  const location = useLocation();

  useEffect(() => {
    console.log(lastLocation, lastLocation !== location.href, location);
    if (lastLocation && lastLocation !== location.href) {
      delete window.document.referrer;
      window.document.__defineGetter__('referrer', function () {
        return lastLocation;
      });
      console.log('calling page');
      page();
    }
    setLastLocation(location.href);
  }, [location.href]);

  useEffect(() => {
    axios.get(SCHEDULE_URL, {params: {t: new Date().getTime()}}).then(({data}) => {
      if (typeof window !== 'undefined') {
        const qs = queryString.parse(window.location.search);
        if (qs.phase) {
          data.default = {...data.default, phase: qs.phase};
        }
      }
      return phaseDispatch({
        type: 'SET_PHASE',
        payload: data
      });
    });
  }, []);
  useEffect(() => {
    registrationId &&
      axios
        .get(`${SF_API_URL}/site/${SITE_ID}/state/`, {
          params: {
            registrationId,
            t: new Date().getTime()
          }
        })
        .then(({data}) => {
          const {firedEvents = []} = data.webinar || {};
          phaseDispatch({
            type: 'SET_PHASE',
            payload: data
          });
          firedEvents.map((e) => userDispatch({type: 'ADD_EVENT', payload: e}));
        });
  }, [registrationId]);
  return children;
}

const App = () => {
  const {phaseData} = useSiteData();
  useEffect(() => {
    return globalHistory.listen(() => typeof window !== 'undefined' && window.analytics && window.analytics.page());
  }, []);
  return (
    <Root>
      <UserProvider>
        <PhaseProvider value={phaseData}>
          <ModalProvider>
            <AppWrapper>
              <Router className="frn-content d-flex align-items-stretch flex-column">
                <Routes default />
              </Router>
            </AppWrapper>
          </ModalProvider>
        </PhaseProvider>
      </UserProvider>
    </Root>
  );
};

export default App;
