import {useEffect, useState} from 'react';
import balanceText from 'balance-text';
import {useDebouncedCallback} from 'use-debounce';

export default function useBalanceText(resizeDebounce = 250, debounce = 1000) {
  const [lastBreakpoint, setLastBreakpoint] = useState(null);
  const _balanceText = useDebouncedCallback(() => {
    balanceText('h1, h2, h3, h4, h5, h6');
    balanceText('.balance-text');
  }, debounce);
  const _resizeHandler = useDebouncedCallback(() => {
    const {innerWidth} = window;
    const currentBreakpoint =
      innerWidth >= 1200 ? 'xl' : innerWidth >= 922 ? 'lg' : innerWidth >= 768 ? 'md' : innerWidth >= 576 ? 'sm' : 'xs';
    if (currentBreakpoint !== lastBreakpoint) {
      setLastBreakpoint(currentBreakpoint);
      _balanceText();
    }
  }, 250);

  useEffect(() => {
    //window.addEventListener('resize', _resizeHandler);
    _balanceText();
    /*
    return () => {
     window.removeEventListener('resize', _resizeHandler);
    };*/
  }, []);
  return _balanceText;
}
