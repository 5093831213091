import React, {useEffect} from 'react';
import {navigate} from '@reach/router';
import MasterClassIndex from './masterclass/index';

export default function(props) {
  useEffect(() => {
    navigate('/masterclass/' + location.search);
  }, []);
  return <MasterClassIndex />;
}
