import React from 'react';

const PhaseStateContext = React.createContext();
const PhaseDispatchContext = React.createContext();

function phaseReducer(state, action) {
  switch (action.type) {
    case 'SET_PHASE': {
      return {phases: {...state.phases, ...action.payload}};
    }
    case 'UPDATE_PHASE': {
      const {context, data} = action.payload;
      return {phases: {...state.phases, [context]: {...state.phases[context], ...data}}};
    }
    case 'ADD_PHASE_EVENT': {
      const context = state.phases[action.payload.context] || {};
      context.firedEvents = [].concat(context.firedEvents, [action.payload.event]);
      return {phases: {...state.phases, [action.payload.context]: {...context}}};
    }
    default: {
      throw new Error('Unhandled action type: ${action.type}');
    }
  }
}

function PhaseProvider({value, children}) {
  const [state, dispatch] = React.useReducer(phaseReducer, value || {});
  return (
    <PhaseStateContext.Provider value={state}>
      <PhaseDispatchContext.Provider value={dispatch}>{children}</PhaseDispatchContext.Provider>
    </PhaseStateContext.Provider>
  );
}

function usePhaseState() {
  const context = React.useContext(PhaseStateContext);
  if (context === undefined) {
    throw new Error('usePhaseState must be used within a PhaseProvider');
  }
  return context;
}

function usePhaseDispatch() {
  const context = React.useContext(PhaseDispatchContext);
  if (context === undefined) {
    throw new Error('usePhaseDispatch must be used within a PhaseProvider');
  }
  return context;
}

export {PhaseProvider, usePhaseState, usePhaseDispatch};
