import React from 'react';
import {Button} from 'reactstrap';
import Video from 'components/Video';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlay} from '@fortawesome/pro-solid-svg-icons';
import classNames from 'classnames';
import {resources} from 'data/resources.json';
import './ResourceTabs.scss';

export default function ResourceTabs({activePanel = 0}) {
  return resources
    ? resources.map(({title, url, children, thumb}, i) =>
        activePanel === i ? (
          <ResourcePanel title={title} url={url} key={i} label={title} thumb={thumb}>
            {children}
          </ResourcePanel>
        ) : null
      )
    : null;
}

export function ResourceTabNav({activePanel = 0, setActivePanel}) {
  return resources.map(({title, thumb}, i) => (
    <div
      className={
        activePanel === i
          ? 'd-flex align-items-center resource-tab resource-tab-active'
          : 'd-flex align-items-center resource-tab'
      }
      onClick={() => setActivePanel(i)}
    >
      <div className="resource-tab-icon">
        {activePanel === i ? (
          <div className="resource-icon resource-icon-play">
            <FontAwesomeIcon icon={faPlay} />
          </div>
        ) : (
          <div className="resource-icon resource-icon-number">{i + 1}</div>
        )}
      </div>
      <div className="resource-tab-image">
        <img className="resource-img" src={thumb} />
      </div>
      <div className="resource-tab-title">
        <div className="resource-title">
          #{i + 1} {title}
        </div>
      </div>
    </div>
  ));
}

export function ResourcePanel({className = '', label, ...props}) {
  return (
    <div className={classNames(`resource-panel mb-5 mb-lg-0`, className)}>
      <div className="resource-panel-video mb-4">
        <Video url={props.url} playing={false} label={label} thumbnail={props.thumb} />
      </div>
      <div className="resource-panel-content">
        <h4 className="seaction-heading resource-panel-header">{props.title}</h4>
        <div className="resource-panel-content">{props.children}</div>
      </div>
    </div>
  );
}
