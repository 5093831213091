import React, {useState, Component} from 'react';
import {Sticky, StickyContainer} from 'react-sticky';
import {Col, Container, Row, Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink} from 'reactstrap';
import {Link, ScrollLink, animateScroll as scroll} from 'react-scroll';
import classNames from 'classnames';
import './SalesNav.scss';

export default function SalesNav(props) {
  const [collapsed, setCollapsed] = useState(true);
  const toggleNavbar = () => setCollapsed(!collapsed);

  function StickyNavLink({scrollToRef, linkText}) {
    return (
      <NavItem>
        <Link
          className="ppt-nav-list-item"
          activeClass="active"
          spy={true}
          offset={-55}
          to={scrollToRef}
          smooth={true}
          href="#"
        >
          {linkText}
        </Link>
      </NavItem>
    );
  }
  return (
    <div className={classNames(`ppt-nav`, props.className)}>
      <Container>
        <Navbar className={classNames(`p-0`, `navbar-light`, `justify-content-end`)} expand="lg">
          <div className="d-lg-none text-dark-gray">Quick Navigation</div>
          <NavbarToggler onClick={toggleNavbar} />
          <Collapse className="justify-content-center" isOpen={!collapsed} navbar>
            <Nav navbar>
              <StickyNavLink scrollToRef="why" linkText="Why This Program" />
              <StickyNavLink scrollToRef="modules" linkText="Modules" />
              <StickyNavLink scrollToRef="bonuses" linkText="Bonuses" />
              <StickyNavLink scrollToRef="instructors" linkText="Instructors" />
              <StickyNavLink scrollToRef="faqs" linkText="FAQs" />
              <StickyNavLink scrollToRef="purchase" linkText="Buy Now" />
            </Nav>
          </Collapse>
        </Navbar>
      </Container>
    </div>
  );
}
