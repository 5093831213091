import {useCallback, useEffect, useState} from 'react';

export default function useExitIntent(timeout = 0) {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = useCallback(() => setIsOpen(isOpen => !isOpen), []);

  const handleMouseLeave = useCallback(e => {
    if (e.clientY < 0) {
      setIsOpen(true);
      document.removeEventListener('mouseleave', handleMouseLeave);
    }
    return true;
  }, []);

  useEffect(() => {
    if (typeof window === 'undefined' || typeof document === 'undefined') return;
    const timer = setTimeout(() => document.addEventListener('mouseleave', handleMouseLeave, false), timeout);
    return () => {
      clearTimeout(timer);
      document.removeEventListener('mouseleave', handleMouseLeave);
    };
  }, []);

  return {
    isOpen,
    toggle
  };
}
