// Imports
import plugin0 from '/opt/atlassian/pipelines/agent/build/node_modules/react-static-plugin-reach-router/browser.api.js'

// Plugins
const plugins = [{
        location: "/opt/atlassian/pipelines/agent/build/plugins/frn-onetrust",
        plugins: [],
        hooks: {}
      },
{
        location: "/opt/atlassian/pipelines/agent/build/plugins/ie11-polyfill",
        plugins: [],
        hooks: {}
      },
{
        location: "/opt/atlassian/pipelines/agent/build/plugins/frn-fix-asset-path",
        plugins: [],
        hooks: {}
      },
{
        location: "/opt/atlassian/pipelines/agent/build/node_modules/react-static-plugin-sass",
        plugins: [],
        hooks: {}
      },
{
        location: "/opt/atlassian/pipelines/agent/build/node_modules/react-static-plugin-source-filesystem",
        plugins: [],
        hooks: {}
      },
{
        location: "/opt/atlassian/pipelines/agent/build/node_modules/react-static-plugin-reach-router",
        plugins: [],
        hooks: plugin0({})
      },
{
        location: "/opt/atlassian/pipelines/agent/build/plugins/frn-everflow",
        plugins: [],
        hooks: {}
      },
{
        location: "/opt/atlassian/pipelines/agent/build/plugins/frn-helpscout-beacon",
        plugins: [],
        hooks: {}
      },
{
        location: "/opt/atlassian/pipelines/agent/build/plugins/frn-segment",
        plugins: [],
        hooks: {}
      },
{
        location: "/opt/atlassian/pipelines/agent/build/plugins/frn-vwo",
        plugins: [],
        hooks: {}
      },
{
        location: "/opt/atlassian/pipelines/agent/build/node_modules/@elbstack/react-static-plugin-dotenv",
        plugins: [],
        hooks: {}
      },
{
        location: "/opt/atlassian/pipelines/agent/build",
        plugins: [],
        hooks: {}
      }]

// Export em!
export default plugins