import React, {useState} from 'react';
import {navigate} from '@reach/router';
import classNames from 'classnames';
import ContactInfoForm from './components/ContactInfoForm';
import SegmentationForm from './components/SegmentationForm';
import {ModalBody} from 'reactstrap';
import Modal from 'components/Modal';

import './OptinForm.scss';

export default function OptinForm({
  id = 'masterclass-optin-form',
  entry = 'Masterclass',
  linkColor = 'purple',
  onComplete = () => {},
  onStepComplete = () => {},
  ...props
}) {
  const [step, setStep] = useState(0);
  return (
    <>
      {(() => {
        switch (step) {
          default:
          case 0:
            return (
              <ContactInfoForm
                onComplete={() => {
                  setStep(1);
                  onStepComplete(1);
                }}
                id={id}
                entry={entry}
              />
            );
          case 1:
            return (
              <SegmentationForm
                onComplete={() => {
                  onComplete();
                  onStepComplete(2);
                }}
                id={id}
              />
            );
        }
      })()}
    </>
  );
}

export function OptinFormInline({
  id = 'masterclass-optin-form',
  entry = 'Masterclass',
  optinButtonText = 'Reserve Your Free Spot Now',
  linkColor = 'purple',
  disableNavigate = false,
  fromInfographic = false,
  submitButtonClassName = undefined,
  ...props
}) {
  const [step, setStep] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const [navigateOnClose, setNavigateOnClose] = React.useState(false);

  const modalToggle = () => setIsOpen((v) => !v);

  const onComplete = React.useCallback(() => {
    modalToggle();
    !disableNavigate && navigate('/masterclass/watch', {state: {fromEntry: entry}});
  }, []);

  const onStepComplete = React.useCallback((step) => step === 1 && setNavigateOnClose(true), []);

  const toggle = React.useCallback(() => {
    if (navigateOnClose && !disableNavigate) navigate('/masterclass/watch', {state: {fromEntry: entry}});
    modalToggle();
  }, [navigateOnClose]);

  return (
    <>
      {(() => {
        switch (step) {
          default:
          case 0:
            return (
              <ContactInfoForm
                onComplete={() => {
                  setStep(1);
                  modalToggle();
                  onStepComplete(1);
                }}
                id={id}
                entry={entry}
                linkColor={linkColor}
                noHeadline
                optinButtonText={optinButtonText}
                submitButtonClassName={submitButtonClassName}
              />
            );
          case 1:
            return (
              <Modal {...props} isOpen={isOpen} toggle={toggle} size="lg">
                <ModalBody className="px-4 pt-0 pb-4">
                  <SegmentationForm
                    onComplete={() => {
                      onComplete();
                      onStepComplete(2);
                    }}
                    id={id}
                  />
                </ModalBody>
              </Modal>
            );
        }
      })()}
    </>
  );
}
