import React, {useState} from 'react';
import {Container, Collapse, Navbar, NavbarToggler, Nav, NavItem, NavLink, Button} from 'reactstrap';
import FacebookLikeButton from 'components/FacebookLikeButton';
import ShareButtons from 'components/ShareButtons';
import classNames from 'classnames';
import './Header.scss';

export default function Header({
  bgColor = 'purple',
  textColor = 'white',
  lockupWhite = false,
  className = undefined,
  logoUrlOverride = undefined,
  logoAltOverride = undefined,
  logoWidthOverride = undefined,
  logoHeightOverride = undefined,
  adLockup = false,
  ...props
}) {
  const [collapsed, setCollapsed] = useState(true);
  const toggleNavbar = () => setCollapsed(!collapsed);
  return (
    <header
      className={classNames(`header`, `background-${bgColor}`, `text-${textColor}`, `d-flex`, `flex-column`, className)}
    >
      <Container className={classNames(`headerContainer`, `my-auto`)}>
        <Navbar className={classNames(`headerNavbar`, `p-0`)} expand="lg" container={false}>
          <div className={classNames(`headerNavbarBrand`, `flex-grow-1`)}>
            {logoUrlOverride ? (
              <img
                className={classNames(`headerLogo`)}
                src={logoUrlOverride}
                alt={logoAltOverride}
                width={logoWidthOverride}
                height={logoHeightOverride}
              />
            ) : (
              <div className="d-flex align-items-center">
                <Button
                  color="headerLogo"
                  href="https://join.foodrevolution.org/offerings/"
                  target="_blank"
                  disabled={adLockup ? false : true}
                >
                  <img
                    className={classNames(`headerLogoFRN`)}
                    src={
                      lockupWhite
                        ? 'https://cdn.foodrevolution.org/global/frn-logo-2024-trademarked-white.svg'
                        : 'https://cdn.foodrevolution.org/global/frn-logo-2024-trademarked-color.svg'
                    }
                    alt="Food Revolution Network logo"
                    width={300}
                    height={116}
                  />
                </Button>
                <div className="headerLogoDivider" />
                <img
                  className={classNames(`headerLogoPROD`)}
                  src={
                    lockupWhite
                      ? 'https://cdn.foodrevolution.org/ppt/frn-pptmasterclass-logo-noicon-white.svg'
                      : 'https://cdn.foodrevolution.org/ppt/frn-pptmasterclass-logo-noicon.svg'
                  }
                  alt="food for health masterclass text logo"
                  width={300}
                  height={70}
                />
              </div>
            )}
          </div>

          <div className="d-flex align-items-center fb-wrap mr-sm-2" style={{maxHeight: '50px'}}>
            <div className="d-none d-lg-inline mr-1">
              <ShareButtons {...props} noDescription />
            </div>
            <FacebookLikeButton />
          </div>

          <NavbarToggler onClick={toggleNavbar} />

          <Collapse
            className={classNames(`headerCollapse`, `mt-2`, `mt-lg-0`, `flex-grow-0`)}
            isOpen={!collapsed}
            navbar
          >
            <Nav navbar>
              <NavItem>
                <NavLink href="https://support.foodrevolution.org/" target="_blank">
                  Support
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="https://community.foodrevolution.org/" target="_blank">
                  Login
                </NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
      </Container>
    </header>
  );
}
