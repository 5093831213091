import React, {useEffect} from 'react';
import {Container, Col, Row} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCartPlus} from '@fortawesome/pro-light-svg-icons/faCartPlus';
import {subscribeToCampaign} from 'react-controlled-ab/datalayers/vwo';
import ABTest from 'react-controlled-ab/components/ABTest';

import {useUserState} from 'contexts/user';
import {usePhaseState} from 'funnel-schedule/phase-context';
import track from 'utils/track';
import useBeacon from 'utils/useBeacon';
import useBalanceText from 'utils/useBalanceText';

import PageMeta from 'components/PageMeta';
import {SectionTriangle, TriangleBottom} from 'components/Elements/Section';
import HeaderVideo from 'components/Elements/HeaderVideo';
import CheckoutButton from 'components/CheckoutButton';
import CountdownTimer from 'components/CountdownTimer';
import {SalesDisclaimer} from 'components/SalesCta';
import ModalExitSales from 'components/ModalExitSales';
import ShareButtons from 'components/ShareButtons';
import Footer from 'components/Footer';
import SalesCopy from 'components/SalesCopy';

export default function SalesIndex({
  noShare = false,
  linkLogo = false,
  noExitIntent = false,
  noFixedVideo = true,
  ctaOverride = null,
  ctaNoTimer = false,
  variant = null,
  version = null,
  ...props
}) {
  const pptLogo = 'https://cdn.foodrevolution.org/ppt/ppt-logo.svg';
  const pptLogoWhite = 'https://cdn.foodrevolution.org/ppt/ppt-logo-white.svg';
  const {bucket, email, registrationId} = useUserState();
  const {
    phases: {
      default: defaultSchedule = {
        phase: 'before'
      },
      sales: salesSchedule = {
        phase: 'during',
        salesEnd: '',
        isSalesDay: false
      },
      webinar: webinarSchedule = {
        phase: 'before'
      }
    }
  } = usePhaseState();

  const salesEnd = new Date(salesSchedule.salesEnd).getTime();
  const salesFullPrice =
    (!email && !registrationId && !ctaOverride) ||
    (!ctaOverride && webinarSchedule.phase === 'downsell') ||
    (!ctaOverride && webinarSchedule.phase === 'after') ||
    ctaOverride === 'sales497';

  const optinTime = webinarSchedule.time * 1000;
  const cartOpenTime = webinarSchedule.cartOpenPeriod * 1000;
  const endOfSalesDay = new Date(webinarSchedule.endOfSalesDay * 1000).getTime();
  const specialTimer = version === 'salesSpecial' && salesSchedule.phase === 'during';
  const timerEnd = specialTimer ? salesEnd : endOfSalesDay;

  const productId = 'C-PPT';
  const productSku = 'C-PPT';
  const productName = 'Plant-Powered & Thriving';
  const productSlug = !salesFullPrice ? 'ppt-special-wlc-30daytrial' : 'ppt-wlc-30daytrial';
  const productValue = !salesFullPrice ? 297 : 497;
  const productPaymentPlanValue = !salesFullPrice ? 109 : 179;

  useEffect(() => {
    track('Product Viewed', {
      product_id: productId,
      sku: productSku,
      name: productName,
      variant: productSlug,
      value: productValue,
      currency: 'usd'
    });
  }, [salesFullPrice]);

  function CtaOffer({iconColor = 'green', btnText = 'Buy Now'}) {
    return (
      <>
        {!salesFullPrice && (
          <h3 className="section-heading mb-3">
            Right Now Just <del>$497</del>
          </h3>
        )}
        <h2 className="text-h1 text-green mt-0 mb-2">{!salesFullPrice ? <>$297</> : <>Right Now $497</>}</h2>
        <CheckoutButton className="offer-box-button" sku={productSku} price={productValue} slug={productSlug}>
          <FontAwesomeIcon
            className="mr-2"
            // @ts-ignore
            icon={faCartPlus}
          />{' '}
          {btnText}
        </CheckoutButton>
        {!salesFullPrice && !ctaNoTimer ? (
          <div className="my-3">
            <h5 className="section-heading mb-2">Your discount expires in:</h5>
            <div className="countdown-dark">
              <CountdownTimer time={timerEnd} />
            </div>
          </div>
        ) : null}
        <SalesDisclaimer iconColor={iconColor} />
        <p className="mt-4 mb-1">Prefer a 3-month payment plan? We’ve got you covered!</p>
        <CheckoutButton
          color="link"
          className="btn-link-checkout"
          sku={productSku}
          price={productPaymentPlanValue}
          slug={productSlug}
          queryParams={{opt: 1}}
        >
          Join now for just ${productPaymentPlanValue}/month!
        </CheckoutButton>
      </>
    );
  }

  useBalanceText();
  useBeacon();
  return (
    <div className="page d-flex flex-column">
      <PageMeta
        title="Join Plant-Powered & Thriving!"
        description="Make FOOD the foundation of YOUR health by joining Plant-Powered & Thriving"
      />

      <div className="page-content">
        <SectionTriangle
          id="join-header"
          color="black"
          imgUrl="https://cdn.foodrevolution.org/ppt/images/1284691274-v3.jpg"
          angle="none"
          innerClass="pt-0"
        >
          <div className="background-black-80 pt-4 pb-2" style={{position: 'relative'}}>
            <Container>
              <Row>
                <Col>
                  <h5 className="section-only-heading text-center text-white">
                    Make FOOD the Foundation of Your Health
                  </h5>
                </Col>
              </Row>
            </Container>
            <TriangleBottom color="black-80" />
          </div>
          <Container>
            <Row className="justify-content-center">
              <Col lg="10">
                <div className="sales-box background-light-gray box-shadow-black-50 rounded text-center">
                  <div className="background-purple text-white p-3 rounded-top">
                    Food Revolution Network Presents...
                  </div>
                  <div className="px-4 px-lg-5 py-4">
                    <img
                      className="mb-4"
                      src={pptLogo}
                      alt="Plant-Powered & Thriving logo"
                      style={{maxHeight: '100px'}}
                    />
                    <img
                      className="mb-3"
                      src="https://cdn.foodrevolution.org/ppt/ppt-product-image.png"
                      alt="Plant-Powered & Thriving course product image"
                      style={{maxHeight: '350px'}}
                    />
                    <p className="balance-text mb-4">
                      Join John & Ocean Robbins and Chef Cynthia Louise for a seven-module course that will give you{' '}
                      <b>science-backed strategies</b> and <b>skills</b> to help you achieve your{' '}
                      <b>
                        <i>healthiest</i>
                      </b>{' '}
                      and{' '}
                      <b>
                        <i>best life.</i>
                      </b>
                    </p>
                    <CtaOffer />
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </SectionTriangle>

        <SectionTriangle id="join-imagine" color="black" type="both" className="text-center text-white">
          <Container>
            <Row>
              <Col>
                <p className="balance-text">
                  Imagine what it will be like to be{' '}
                  <b>
                    <i>excited about your meals</i>
                  </b>{' '}
                  and confident that friends and family will enjoy your food as much as you do. Imagine spending{' '}
                  <b>
                    <i>less time planning</i>
                  </b>{' '}
                  and stressing about meals and more time doing the things you love, including time with friends and
                  family, sleep, exercise, gardening, and preparing{' '}
                  <b>
                    <i>meals you actually enjoy…</i>
                  </b>
                </p>
              </Col>
            </Row>
          </Container>
        </SectionTriangle>

        {!salesFullPrice && (
          <SectionTriangle id="join-video" color="white" type="none" innerClass="pb-0">
            <Container>
              <Row className="d-flex justify-content-center">
                <Col lg="10" className="video-col px-0 px-sm-3">
                  <HeaderVideo
                    bgColor="black"
                    className="text-white"
                    sku={productSku}
                    price={productValue}
                    slug={productSlug}
                    url="https://customer-ros8xhsmbkksflna.cloudflarestream.com/b50787aa384ea5ff614a6df1582d85f7/watch"
                    playing={variant === 'ad' ? 'false' : 'true'}
                    sales
                    headline={<h4 className="section-heading text-white">Make FOOD the foundation of YOUR health</h4>}
                    salesBtnText="Join Now!"
                    noFixed={noFixedVideo}
                    label="PPT - Sales Video - Join"
                  />
                </Col>
              </Row>
            </Container>
          </SectionTriangle>
        )}

        <SalesCopy version="sales" variant={variant} salesFullPrice={salesFullPrice}>
          <>
            <div className="cta-headline text-white text-center">
              <h3 className="section-heading text-400 mb-5">
                Take Charge of Your Health with{' '}
                <b>
                  <i>Plant-Powered & Thriving</i>
                </b>
              </h3>
            </div>
            <div className="cta-box background-light-gray rounded box-shadow-black-50 text-center p-4 p-lg-5">
              <img
                className="mb-4"
                src="https://cdn.foodrevolution.org/ppt/ppt-product-image.png"
                alt="Plant-Powered & Thriving course product image"
                style={{maxHeight: '250px'}}
              />
              <h4 className="section-heading text-400">
                Get instant and unlimited access — <i>guaranteed!</i>
              </h4>
              <CtaOffer {...props} />
            </div>
            <Row className="trees-for-future text-white d-flex align-items-center justify-content-center mt-5">
              <Col xs="11" lg="8" className="mb-4 mb-lg-0">
                <h6 className="section-only-heading text-center text-lg-left text-400">
                  For every new order, we make a donation to Trees for the Future, enabling them to plant another
                  organic fruit or nut tree in a low-income community.
                </h6>
              </Col>
              <Col xs="11" lg="3" className="text-center">
                <img
                  className="logo-trees"
                  alt="Trees for the Future logo"
                  src="https://cdn.foodrevolution.org/global/trees-logo-white.png"
                  width={1080}
                  height={442}
                  style={{maxHeight: '100px', width: 'auto'}}
                />
              </Col>
            </Row>
          </>
        </SalesCopy>
      </div>

      {!noExitIntent || !salesFullPrice ? <ModalExitSales /> : null}
      <div className="footer-content mt-auto">
        <Footer />
        <ShareButtons floating />
      </div>
    </div>
  );
}
