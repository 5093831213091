import React, {useEffect} from 'react';
import track from 'utils/track';
import SalesIndex from './join';

export default function () {
  useEffect(() => {
    track('Product Viewed', {
      product_id: 'C-PPT',
      sku: 'C-PPT',
      name: 'Plant-Powered & Thriving',
      variant: 'ppt-special-wlc-30daytrial',
      value: 297,
      currency: 'usd'
    });
  }, []);
  return <SalesIndex ctaOverride="sales297" ctaNoTimer />;
}
