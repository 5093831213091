import React from 'react';
import {usePhaseState} from '../phase-context';

function ShowIf(props) {
  const {phases} = usePhaseState();
  const context = props.context || 'default';
  if (!(context in phases && 'phase' in phases[context])) return null;
  return [].concat(props.phase).includes(phases[context].phase) ? props.children : null;
}

export default ShowIf;
