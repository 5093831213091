import React, {Component, useCallback, useEffect} from 'react';
import {Button, Col, Container, Row} from 'reactstrap';
import PageMeta from 'components/PageMeta';
import Video from 'components/Video';
import CheckoutButton from 'components/CheckoutButton';
import Footer from 'components/Footer';
import logoTrees from 'static/logo/logo-trees-for-future-white.png';

import Section, {SectionImage} from 'components/Elements/Section';
import useBeacon from 'utils/useBeacon';
import IconList, {IconListItem} from 'components/Elements/IconList';
import {SalesWlcAlumni} from 'components/SalesCta';
import HeaderVideo from 'components/Elements/HeaderVideo';
import johnOceanVeggies from 'static/speaker/john-ocean-veggies.jpg';
import imgQuestion from 'static/img-question-food.jpg';
import imgBurger from 'static/img-burger.jpg';
import imgChoice from 'static/img-woman-choice.jpg';
import {ImgBulletCard} from 'components/Elements/ImgBullet';
import {TestimonialCard, TestimonialCircle} from 'components/Testimonial';
import ModalExitAlumni from 'components/Modals/ModalExitAlumni';

export default function () {
  useBeacon();
  return (
    <div className="page page-nosocial">
      <PageMeta title="Join WHOLE Life Club Today" description="Make FOOD the foundation of YOUR health" />

      <div className="page-content">
        <section className="section-header background-black text-white py-4">
          <Container>
            <Row>
              <Col className="text-center">
                <h3 className="section-heading mt-3 mb-2">
                  WHOLE Life Club <br className="d-none d-sm-block d-md-none" />
                  “is like my bible for <br className="d-none d-md-block" />
                  living <br className="d-none d-sm-block d-md-none" />
                  the healthiest life I can.”
                </h3>
                <h3 className="text-400 m-0">
                  <em>-Laurie, Trumbull, CT</em>
                </h3>
              </Col>
            </Row>
          </Container>
        </section>
        <Section
          id="hbc-alumni-video"
          className="section-header"
          innerClass="text-white text-shadow pt-0 pt-sm-3"
          color="black"
        >
          <Container>
            <Row className="d-flex justify-content-center">
              <Col lg="10" className="video-col px-0 px-sm-3">
                <HeaderVideo
                  bgColor="black"
                  sku="WLC"
                  price="127"
                  slug="wlc-hbc-alumni"
                  url="https://www.youtube.com/watch?v=JqNORHHk4G8"
                  sales
                  heading={<>Become a WHOLE Life Club Member Today!</>}
                  noFixed={false}
                  label="PPT - WLC - Alumni"
                />
              </Col>
            </Row>
            <Row className="text-center mt-5">
              <Col>
                <h5 className="section-heading text-400 mb-2">
                  “I can actually RUN for the first time in <br className="d-none d-sm-block d-md-none" />
                  20 years, and <br className="d-none d-md-block" />
                  my body doesn't ache. <br className="d-none d-sm-block d-md-none" />
                  Thank you, WHOLE Life Club!”
                </h5>
                <h5 className="text-400 m-0">
                  <em>-Kathy, Burlington IA</em>
                </h5>
              </Col>
            </Row>
          </Container>
        </Section>

        <Section id="hbc-alumni-cta-1" color="gradient-indigo-purple-right">
          <Container>
            <Row>
              <Col>
                <h3 className="section-heading text-400 text-white text-center mb-4">
                  You’ve taken action with <br className="d-none d-md-block d-lg-none" />
                  Plant-Powered & Thriving. <br className="d-none d-md-block" />
                  Now it’s time to sustain it — for life.
                </h3>
              </Col>
            </Row>
            <SalesWlcAlumni />
          </Container>
        </Section>

        <section className="section section-angle-bottom-offset section-note background-image-fruit-veggies background-image-center background-cover background-fixed background-overlay-black-50">
          <div className="section-inner">
            <Container>
              <Row>
                <Col>
                  <div className="background-white px-3 py-4 px-lg-5 py-lg-5 my-3">
                    <h3 className="section-heading text-center mb-5">
                      An Important Message from Food Revolution Network Co-founders John Robbins & Ocean Robbins
                    </h3>
                    <p>
                      <strong>Toxic food and sickness don’t have to be the norm.</strong>
                    </p>
                    <p>
                      With today’s science-backed knowledge,{' '}
                      <em>
                        it’s now possible to slash your risk of cancer, heart disease, Alzheimer’s, type 2 diabetes,
                        osteoporosis, obesity, rheumatoid arthritis, viral infection, and other health challenges
                      </em>{' '}
                      — by eating the right foods.
                    </p>
                    <p>But the reality is it isn’t what you learn, or even what you know, that matters most.</p>
                    <p>
                      <strong>It’s what you do that matters most.</strong>
                    </p>
                    <p>
                      It’s time to make your health a priority, so you can protect yourself from illness, and so you can
                      claim the wellness that you deserve. Not just for a day, or a week, or a month.
                    </p>
                    <p>For your WHOLE life.</p>
                    <p>
                      We’ve written bestselling books, and we’ve held online Food Revolution Summits that have inspired
                      millions of people around the world. We’ve seen a lot of people get inspired. Maybe you’re one of
                      them.
                    </p>
                    <p>
                      We created WHOLE Life Club to help you sustain positive action, so you can get lasting results.
                      And because we’re so passionate about sharing this opportunity with as many people as possible,
                      for a limited time, we’re pushing the price down as FAR as we possibly can (while still being able
                      to afford to keep offering you a world class experience!).
                    </p>
                    <p>
                      You can look better. Feel better. And, most importantly, you can have the energy to bring your
                      absolute best to the projects and people that matter most to you.
                    </p>

                    <Row className="d-flex align-items-center justify-content-center mt-4">
                      <Col xs="12" lg="8" className="mb-4 mb-lg-0">
                        <h2 className="section-only-heading text-center">
                          So join us right now and let’s build on that momentum today!
                        </h2>
                      </Col>
                      <Col xs="10" sm="8" lg="4" xl="3">
                        <img className="rounded-circle" src={johnOceanVeggies} />
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </section>

        <Section id="hbc-alumni-cta-2" color="gradient-indigo-purple-right" angle="both">
          <Container>
            <Row>
              <Col>
                <h2 className="section-heading text-white text-center mb-3">
                  A Healthy, Happy, Abundant Life <em>Is</em> Possible…
                </h2>
                <h3 className="text-white text-center mt-0">Become a WHOLE Life Club Member Today!</h3>
              </Col>
            </Row>
            <SalesWlcAlumni />
          </Container>
        </Section>

        <Section id="hbc-alumni-premium" color="light-gray">
          <Container>
            <Row>
              <Col>
                <h3 className="section-heading text-center mb-3 mb-lg-5">
                  Join WHOLE Life Club and gain premium access to:
                </h3>
              </Col>
            </Row>
            <Row className="d-flex align-items-center justify-content-center mb-5">
              <Col xs="10" lg="5" className="mb-3 mb-lg-0">
                <img
                  className=""
                  src="https://cdn.foodrevolution.org/wlc/wlc-laptop-mock.png"
                  width="1256"
                  height="752"
                  alt="WHOLE Life Club graphic"
                />
              </Col>
              <Col xs="12" lg="7">
                <IconList iconColor="purple">
                  <IconListItem>
                    <strong>Monthly Action Hour events</strong> featuring trustworthy health revolutionaries...
                  </IconListItem>
                  <IconListItem>
                    Motivating videos, practical <strong>Action Checklists,</strong> fabulous recipes...
                  </IconListItem>
                  <IconListItem>
                    A <strong>private, moderated community...</strong>
                  </IconListItem>
                  <IconListItem>
                    <strong>Our WHOLE library</strong> featuring 20+ Action Hours with leading edge experts, hundreds of
                    fabulous recipes (all easy to search so you have what you need, when you need it!), 60+ short action
                    step videos, and much more...
                  </IconListItem>
                </IconList>
                <p className="text-center">
                  <strong>
                    All designed to help you easily and successfully implement, improve (and LOVE!) your healthy living
                    lifestyle.
                  </strong>
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <TestimonialCard id="wlc-jean-m" className="mb-3" />
                <p className="text-center text-16">
                  <em>
                    After joining WHOLE Life Club, many participants have adopted a whole foods, plant-centered dietary
                    pattern. Their testimonials reflect their unique experience. Your specific results will vary.
                  </em>
                </p>
              </Col>
            </Row>
          </Container>
        </Section>

        <Section id="hbc-alumn-challenges" color="white">
          <Container>
            <Row className="mb-5">
              <Col>
                <h2 className="section-heading text-center">
                  3 Challenges Most People Face When Trying to Eat Well, Be Healthy, and Prevent Disease...
                </h2>
              </Col>
            </Row>
            <Row className="d-flex align-items-center justify-content-center mb-4">
              <Col xs="10" sm="8" lg="5" className="text-center mb-4 mb-lg-0">
                <img src={imgQuestion} />
              </Col>
              <Col xs="12" lg="7">
                <h3 className="section-heading">Challenge #1 — Confusion & Conflicting Advice</h3>
                <p>
                  It seems like everyone has an opinion on soy, vegetables, grains, meat, fats, carbs, gluten, and what
                  new, trendy diet is best for YOU.
                </p>
                <p>But most people feel confused now more than ever.</p>
                <p>
                  <strong>
                    In a vast ocean of information (and, unfortunately, tsunamis of misinformation), the status quo is
                    usually what prevails. But that only leads to more of the same!
                  </strong>
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <IconList className="mt-5">
                  <IconListItem>
                    <h3 className="section-only-heading">
                      Solution: Get Clear, Trustworthy Wisdom from Experts You Can Trust
                    </h3>
                  </IconListItem>
                </IconList>
                <p>
                  Join WHOLE Life Club and you’ll get fresh, valuable, and credible insights from trusted leaders.
                  Whether you want guidance on nutrient needs, or how to survive social situations without falling off
                  the wagon, you’ll have solid science and a steady community of support to back you on every step of
                  the journey. And with forums, videos, and premium access to monthly events with health
                  revolutionaries, you’ll be the first to know about breakthroughs, and you’ll have the support you need
                  to confidently debunk the latest fads.
                </p>
              </Col>
            </Row>
            <Row className="my-5">
              <Col>
                <TestimonialCard id="wlc-laurie-c" />
              </Col>
            </Row>
            <Row className="d-flex align-items-center justify-content-center mb-4">
              <Col xs="10" sm="8" lg="5" className="text-center mb-4 mb-lg-0">
                <img src={imgBurger} />
              </Col>
              <Col xs="12" lg="7">
                <h3 className="section-heading">Challenge #2 — Bad Habits</h3>
                <p>
                  It’s relatively easy to eat well when you already feel good, have plenty of time on your hands, and
                  don’t mind spending what might feel like your whole paycheck on wholesome foods.
                </p>
                <p>
                  But for most of us, healthy eating — like life — isn’t always easy. And{' '}
                  <strong>
                    sometimes it can feel like the whole world, not to mention a lifetime of habits, are pulling you in
                    the wrong direction.
                  </strong>
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <IconList className="mt-5">
                  <IconListItem>
                    <h3 className="section-only-heading">Solution: Build Healthy Habits That Give You RESULTS</h3>
                  </IconListItem>
                </IconList>
                <p>
                  Want to create positive and sustained habit change? You’ll need a smart strategy implemented
                  effectively.
                </p>
                <p>
                  WHOLE Life Club provides steady guidance and resources to help you hone and enjoy positive habits.
                  From menu guidance to action videos, and from shopping list tools to simple checklists, we’ll show you
                  how easy it can be to implement, sustain, and optimize your path to success. In good times and bad,
                  your body deserves the best possible nutrients to help you thrive. We’ll show you how you can set
                  yourself up for lasting positive results!
                </p>
                <p>
                  And in case you’re worried about boring, tasteless food — fear not!{' '}
                  <strong>With five fabulous new recipes every week</strong> (that are tasty, nutritious, and easy to
                  prepare) plus total access to an easily searchable library of hundreds,{' '}
                  <strong>you’ll love healthy food more than ever.</strong>
                </p>
              </Col>
            </Row>
            <Row className="mb-5">
              <Col>
                <TestimonialCard id="wlc-kathy-w" className="my-5" />
              </Col>
            </Row>
            <Row className="d-flex align-items-center justify-content-center mb-4">
              <Col xs="10" sm="8" lg="5" className="text-center mb-4 mb-lg-0">
                <img className="rounded-circle border" src={imgChoice} />
              </Col>
              <Col xs="12" lg="7">
                <h3 className="section-heading">Challenge #3 — Social Pressure & Isolation</h3>
                <p>
                  We all know it’s a heck of a lot harder to live a healthy lifestyle when your friends and family
                  don’t.
                </p>
                <p>
                  <em>
                    This stress becomes even more powerful when you have an underlying illness or condition that you’re
                    concerned about tending to, that no one else understands.
                  </em>{' '}
                  You may worry about not being able to share a meal with a loved one, missing out on a weekend
                  barbecue, or not eating the food at work.
                </p>
                <p>
                  When people are bonding around unhealthy foods, you may feel sad, disconnected, or disappointed. They
                  might also express discomfort that you’re not joining in.
                </p>
                <p>
                  You might even sometimes compromise your food values in order to fit in, or for fear of hurting
                  someone’s feelings. (And ultimately regret it.)
                </p>
                <p>
                  Food can be social. But{' '}
                  <strong>
                    unless you consciously create positive social reinforcement, your social life can actually pull you
                    in an unhealthy direction.
                  </strong>
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <IconList className="mt-5">
                  <IconListItem>
                    <h3 className="section-only-heading">Solution: A Welcoming, Supportive Community</h3>
                  </IconListItem>
                </IconList>

                <p>
                  The instant you join, you’ll gain immediate access to a moderated community of health-minded people
                  who are ready to{' '}
                  <strong>help you overcome whatever challenges you may face now — or in the future.</strong>
                </p>
                <p>
                  A community to cheer you on, to celebrate your victories... and to support you in achieving your
                  health and lifestyle goals.
                </p>
              </Col>
            </Row>
            <TestimonialCard id="wlc-genevieve-d" className="mt-5" />
          </Container>
        </Section>

        <Section id="hbc-alumni-cta-3" color="gradient-indigo-purple-right">
          <Container className="pb-4">
            <Row>
              <Col>
                <h2 className="section-heading text-white text-center mb-3">Join Now!</h2>
                <h3 className="text-white text-center mt-0">Become a WHOLE Life Club Member Today!</h3>
              </Col>
            </Row>
            <SalesWlcAlumni />
          </Container>
        </Section>

        <SectionImage
          id="hbc-alumni-themes"
          className="section-sales-themes"
          imgPosition="right"
          imgBgPosition="left-center"
          color="light-gray"
          imgUrl={require('static/backgrounds/thumbtack-square.jpg')}
          imgUrlMobile={require('static/backgrounds/thumbtack.jpg')}
        >
          <h4 className="section-heading">
            Themes include Immunity, Disease Prevention, Detox, Weight Loss, Heart Health, Anti-Cancer Living, Brain
            Health, Mental Health, Hormonal Balance, and More! Plus We’re Always Adding New Ones for You.
          </h4>
          <p>
            Every month, WHOLE Life has a theme. It’s like a mini-course to help you make progress in an area that
            matters.
          </p>

          <p>
            <strong>
              Here are some themes we’ve already focused on. When you join, you get them all instantly, on demand!
            </strong>
          </p>
          <ul>
            <li>
              <strong>Foods to Fight Cancer and Thrive in Every Cell</strong> — see how it’s possible to prevent and
              even heal cancer
            </li>
            <li>
              <strong>Detoxing and Cleansing</strong> — use food to heal your body naturally
            </li>
            <li>
              <strong>Heart Health</strong> — find out the 4 pillars of a healthy heart and a healthy life
            </li>
            <li>
              <strong>Weight Loss and Food Freedom</strong> — discover how to rewire your brain to eat what’s good for
              you
            </li>
            <li>
              <strong>Pesticides and Food Safety</strong> — protect yourself from toxic food
            </li>
          </ul>
          <p>
            At the beginning of each month, Food Revolution Network shares a new Action Hour with a health revolutionary
            who’s an expert on the theme of the month. As a WHOLE Life Club Member, you’ll get Insider Access so you can
            submit questions for the speaker, and so you can have total access and get written transcripts afterwards.
          </p>

          <p>
            You have a voice as a member, and themes for each month are chosen based on topics of greatest interest to
            members (like you!). You get to influence the content of WHOLE Life Club, so it will best meet your
            interests and needs!
          </p>
        </SectionImage>

        <Section id="hbc-alumni-gain" color="white" angleWrapClass="d-lg-none">
          <Container>
            <Row className="d-flex justify-content-center">
              <Col>
                <h2 className="section-only-heading text-center">Join Now and Gain:</h2>
              </Col>
            </Row>
          </Container>
        </Section>

        <SectionImage id="hbc-alumni-wisdom" imgPosition="left" color="white" imgUrl={require('static/img-wisdom.jpg')}>
          <h2 className="section-heading">Wisdom</h2>
          <p>
            <em>“Knowledge you can use, from experts you can trust”</em>
          </p>
          <IconList>
            <IconListItem>
              <strong>Premium access to monthly Action Hour interviews</strong> with Health Revolutionaries (including
              opportunities to <em>share your opinions and ask your questions</em>) plus ongoing access to recordings,
              transcripts, and follow-up Action Checklists.
            </IconListItem>
            <IconListItem>
              <strong>Weekly, easy-to-implement, Action Videos and Checklists,</strong> plus bonus special reports and
              other resources so you can dive deeper into topics that matter to you.
            </IconListItem>
          </IconList>
        </SectionImage>

        <SectionImage
          id="hbc-alumni-recipes"
          imgPosition="right"
          color="light-gray"
          imgUrl={require('static/img-recipes.jpg')}
        >
          <h2 className="section-heading">Recipes</h2>
          <p>
            <em>“Love foods that love you back”</em>
          </p>
          <IconList>
            <IconListItem>
              <strong>The WHOLE Resource Library</strong> of over 400 delicious and healthy plant-based recipes that is
              completely searchable for your every need, whether it be breakfast, lunch, or dinner, or by ingredient,
              allergen, or regional cuisine.
            </IconListItem>
            <IconListItem>
              A new set of <strong>hand-selected, delicious, whole foods, plant-powered recipes every week</strong> that
              are nutritious, affordable, easy to prepare, bright-line eating friendly, and that{' '}
              <strong>taste amazing</strong>{' '}
              <em>(including support for anyone on restricted diets: gluten-, sugar-, oil-, and/or salt-free).</em>
            </IconListItem>
            <IconListItem>
              Tips and tricks to help you <strong>make recipe and menu planning easy and enjoyable.</strong>
            </IconListItem>
          </IconList>
        </SectionImage>

        <SectionImage
          id="hbc-alumni-community"
          imgPosition="left"
          color="white"
          imgUrl={require('static/img-community.jpg')}
        >
          <h2 className="section-heading">Community</h2>
          <p>
            <em>“Alone we can do so little; together, we can do so much.”</em>
            <br />
            &mdash; Helen Keller
          </p>
          <IconList>
            <IconListItem>
              <strong>Join with other health-minded people</strong> to share insights, receive guidance, spread the food
              revolution, build friendships, and achieve your lifestyle goals.
            </IconListItem>
            <IconListItem>
              Use the moderated forum to <strong>ask your questions and get real, trustworthy, answers.</strong>
            </IconListItem>
            <IconListItem>
              Start and <strong>join in conversations on topics that matter to you</strong> — and connect with peers who
              share your values and your concerns. Whether you want to talk about caring for a sick spouse or parent,
              digestive issues, fatigue, weight loss, heart health, loving and living with a spouse who doesn’t share
              your food values, raising healthy kids, growing food, or changing the world —{' '}
              <strong>you’ll find a powerful and uplifting community to support you.</strong>
            </IconListItem>
          </IconList>
        </SectionImage>

        <section className="section section-angle-bottom-offset section-note background-image-greenery-notable background-image-center background-cover background-fixed background-overlay-black-50">
          <div className="section-inner">
            <Container>
              <Row>
                <Col>
                  <div className="background-white px-3 py-4 px-lg-5 py-lg-5 my-3">
                    <h2 className="section-heading text-center mb-5">Our Commitment to You:</h2>
                    <IconList>
                      <IconListItem>
                        <strong>
                          At Food Revolution Network, we promise WHOLE Life Club will put you on the fast-track to the
                          clarity and confidence you deserve.
                        </strong>
                      </IconListItem>
                      <IconListItem>
                        You'll <strong>love your relationship with food</strong> more than ever.{' '}
                        <em>Say goodbye to food stress. Say hello to more pleasure.</em>
                      </IconListItem>
                      <IconListItem>
                        You'll find it <strong>easier and easier to do the right thing</strong> for your health — even
                        when your energy’s low. <em>Goodbye, bad habits. Hello, good ones.</em>
                      </IconListItem>
                      <IconListItem>
                        You’ll feel <strong>clearer and more informed,</strong> so you can consistently make wise food
                        choices that empower your health and that of those you love.{' '}
                        <em>Goodbye, confusion. Hello, clarity.</em>
                      </IconListItem>
                      <IconListItem>
                        You’ll enjoy a <strong>powerful community</strong> that lifts you up and supports your healthy
                        eating path. <em>Goodbye, swimming upstream. Hello, wind at your back.</em>
                      </IconListItem>
                    </IconList>
                    <h4 className="text-center">
                      So if you’re ready to make steady and consistent progress towards your health and life goals...
                    </h4>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </section>

        <Section id="hbc-alumni-cta-5" color="gradient-indigo-purple-right" angle="both">
          <Container>
            <Row>
              <Col>
                <h2 className="section-heading text-white text-center mb-3">Join Now!</h2>
                <h3 className="text-white text-center mt-0">Become a WHOLE Life Club Member Today!</h3>
              </Col>
            </Row>
            <SalesWlcAlumni />
          </Container>
        </Section>

        <Section color="light-gray">
          <Container>
            <Row>
              <Col>
                <h3 className="section-heading text-center mb-3 mb-lg-5">
                  Join WHOLE Life Club and gain premium access to:
                </h3>
              </Col>
            </Row>
            <Row className="d-flex align-items-center justify-content-center">
              <Col xs="10" lg="5" className="mb-3 mb-lg-0">
                <img
                  className=""
                  src="https://cdn.foodrevolution.org/wlc/wlc-laptop-mock.png"
                  width="1256"
                  height="752"
                  alt="WHOLE Life Club graphic"
                />
              </Col>
              <Col xs="12" lg="7">
                <IconList>
                  <IconListItem>
                    <strong>Monthly Action Hour events</strong> featuring trustworthy health revolutionaries...
                  </IconListItem>
                  <IconListItem>
                    Motivating videos, practical <strong>Action Checklists,</strong> fabulous recipes...
                  </IconListItem>
                  <IconListItem>
                    A <strong>private, moderated community...</strong>
                  </IconListItem>
                  <IconListItem>
                    <strong>Our WHOLE library</strong> featuring 20+ Action Hours with leading edge experts, hundreds of
                    fabulous recipes (all easy to search so you have what you need, when you need it!), 60+ short action
                    step videos, and much more...
                  </IconListItem>
                </IconList>
                <p className="text-center">
                  <strong>
                    All designed to help you easily and successfully implement, improve (and LOVE!) your healthy living
                    lifestyle.
                  </strong>
                </p>
              </Col>
            </Row>
          </Container>
        </Section>

        <Section id="hbc-alumni-foryou" color="white">
          <Container>
            <Row>
              <Col>
                <h2 className="section-heading mb-5">WHOLE Life Club Is for You If:</h2>
              </Col>
            </Row>
            <ImgBulletCard img={require('static/foryou-whole-positive.jpg')}>
              You know that food matters, and you want to{' '}
              <strong>improve your healthy lifestyle for lasting positive results.</strong>
            </ImgBulletCard>
            <ImgBulletCard img={require('static/foryou-whole-delicious.jpg')}>
              You’re ready for taking care of your health to be <strong>fun and delicious.</strong>
            </ImgBulletCard>
            <ImgBulletCard img={require('static/foryou-whole-learning.jpg')}>
              You want to take your learning further and <strong>take action on trusted health information.</strong>
            </ImgBulletCard>
            <ImgBulletCard img={require('static/foryou-whole-support.jpg')}>
              <strong>You want to help people in your life improve their health</strong> with simple actions and
              trustworthy health tips — so you can be a beacon of wisdom and a source of support.
            </ImgBulletCard>
            <ImgBulletCard img={require('static/foryou-whole-plants.jpg')}>
              <strong>You want to base your diet around whole plant foods.</strong>
            </ImgBulletCard>
            <ImgBulletCard img={require('static/foryou-whole-revolution.jpg')}>
              <strong>You’re part of the food revolution</strong> and want healthy, ethical, and sustainable food for
              everyone who eats.
            </ImgBulletCard>
          </Container>
        </Section>

        <Section id="hbc-alumni-cta-6" color="gradient-indigo-purple-right">
          <Container>
            <Row className="text-center text-white">
              <Col>
                <h3 className="section-heading mb-2">Get The Lasting RESULTS You Want.</h3>
                <h3 className="mt-0">Join WHOLE Life Club.</h3>
                <p className="mb-4">
                  <em>From confusion to clarity. From concern to confidence. From knowledge to action.</em>
                </p>
                <h3 className="mt-0">Are you ready?</h3>
              </Col>
            </Row>
            <Row className="d-flex justify-content-center">
              <Col lg="10" xl="8">
                <div className="purchase-card">
                  <div className="purchase-card-header background-black text-white rounded-top p-3 p-lg-4">
                    <h5 className="purchase-card-title text-400 text-center m-0">Become A WHOLE Life Club Member</h5>
                  </div>
                  <div className="purchase-card-content background-white rounded-bottom p-4 p-lg-5">
                    <h3 className="section-heading text-green text-center mb-4">
                      All for just $127 per year (that’s a $120 savings off the regular annual price)!
                    </h3>
                    <div className="list-simple-check-wrap">
                      <IconList>
                        <IconListItem>Access to every Action Hour AND follow-up Action Checklists.</IconListItem>
                        <IconListItem>
                          Weekly, quick Action of the Week videos and an accompanying Action Guide.
                        </IconListItem>
                        <IconListItem>5 delicious, whole foods, plant-powered recipes per week.</IconListItem>
                        <IconListItem>A sustained and moderated online community.</IconListItem>
                        <IconListItem>Annual rates that will never go up as long as you remain a member.</IconListItem>
                      </IconList>
                    </div>
                    <CheckoutButton
                      className="btn-block"
                      sku="WLC"
                      productName="WHOLE Life Club"
                      price="127"
                      slug="wlc-hbc-alumni"
                    />
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="text-center text-white">
              <Col>
                <h5>It’s time to invest in your health — the most valuable thing you can ever have!</h5>
              </Col>
            </Row>
            <Row className="trees-for-future text-white d-flex align-items-center justify-content-center mt-4">
              <Col xs="11" lg="8" className="mb-4 mb-lg-0">
                <h4 className="section-heading text-center text-lg-left mb-2">
                  Plus, You’ll Be Supporting Healthy, Ethical, Sustainable Food for All…
                </h4>
                <h6 className="section-only-heading text-center text-lg-left text-400">
                  And for every paid membership,{' '}
                  <em>
                    Food Revolution Network donates to Trees for the Future, enabling them to plant another organic
                    fruit or nut tree in a low-income community.
                  </em>
                </h6>
              </Col>
              <Col xs="11" lg="3" className="text-center">
                <img className="logo-trees" src={logoTrees} />
              </Col>
            </Row>
          </Container>
        </Section>

        <Section id="hbc-alumni-testimonials" color="white">
          <Container>
            <Row>
              <Col>
                <TestimonialCircle id="wlc-heidi-h" />
                <hr className="my-5" />
                <TestimonialCircle id="wlc-tina-g" imgPosition="right" />
                <hr className="my-5" />
                <TestimonialCircle id="wlc-alexa-p" />
              </Col>
            </Row>
          </Container>
        </Section>

        <Section id="hbc-alumni-guarantee" color="light-gray">
          <Container>
            <Row>
              <Col>
                <div className="guarantee-box rounded background-white box-shadow-black-50">
                  <h2 className="section-heading guarantee-heading text-center text-sm-left">
                    Our 60-Day Better Than Money Back Risk-Free Guarantee
                  </h2>
                  <p>Take up to 60 days to see what WHOLE Life Club is all about.</p>
                  <p className="mb-4">We guarantee that you and your family will:</p>
                  <IconList className="mb-4">
                    <IconListItem>
                      Have the tools and information to improve your health right away, helping you eat better and enjoy
                      more vitality.
                    </IconListItem>
                    <IconListItem>Feel empowered knowing the truth about the foods you eat.</IconListItem>
                    <IconListItem>Make meaningful progress towards YOUR healthy eating goals.</IconListItem>
                  </IconList>
                  <p>
                    Your satisfaction is important to us! If for some reason you aren’t 100% thrilled with your
                    purchase, you have 60 days to request a prompt, total refund. Email us at{' '}
                    <a className="link-black text-underline" href="mailto:support@foodrevolution.org">
                      support@foodrevolution.org
                    </a>{' '}
                    or phone us at{' '}
                    <a className="link-black text-underline" href="tel:8318244779">
                      (831) 824-4779
                    </a>
                    . You can also hit reply to any email we send you with your request.
                  </p>
                  <p className="mb-0">
                    If for any reason you request a refund, everything you've already downloaded is still yours to keep.
                    That’s why we call our guarantee “risk-free!”
                  </p>

                  <div className="guarantee-box-inner rounded mt-4">
                    <CheckoutButton
                      sku="WLC"
                      price="127"
                      productName="WHOLE Life Club"
                      slug="wlc-hbc-alumni"
                      color="link-guarantee"
                      className="mb-3"
                    >
                      Yes, Join Now to Get TOTAL ACCESS TO Food Revolution Network’s WHOLE Life Club!
                    </CheckoutButton>

                    <p>
                      You understand that you’ll be receiving the entire program for a year, for a small, one-time
                      investment, and that you will be automatically renewed at the special price of just $127 per year
                      ($19/month), unless you cancel your membership.
                    </p>

                    <p>
                      You risk nothing because we’re offering a 60-day, risk-free, money-back guarantee if you are not
                      thrilled with the club.
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </Section>

        <Section id="hbc-alumni-cta-7" color="gradient-indigo-purple-right" angle="none">
          <Container>
            <Row>
              <Col>
                <h2 className="section-heading text-white text-center mb-3">Take the next step…</h2>
              </Col>
            </Row>
            <SalesWlcAlumni />
          </Container>
        </Section>
      </div>

      <ModalExitAlumni />
      <Footer />
    </div>
  );
}
