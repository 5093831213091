import React, {useCallback, useState} from 'react';
import {Button, Container, Col, Row, Collapse} from 'reactstrap';
import ReactMarkdown from 'react-markdown';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faMinus, faPlus} from '@fortawesome/pro-solid-svg-icons';
import classNames from 'classnames';
import {faq} from 'data/faq.json';
import './FaqList.scss';

function FaqItem({id, question, answer, borderColor, ...props}) {
  return (
    <div className={classNames(`faq-item faq-item-border-${borderColor}`)}>
      <h3 className="section-heading faq-item-question">Q: {question}</h3>
      <p className="faq-item-answer">{answer}</p>
    </div>
  );
}

FaqItem.defaultProps = {
  borderColor: 'black-15'
};

export default function FaqList({id, ...props}) {
  return faq.map((faq) => <FaqItem {...faq} key={faq.id} {...props} />);
}

export function FaqCard({faqlist = faq, slice = 0, ...props}) {
  const [activeCards, setActiveCards] = React.useState([]);
  const onClick = React.useCallback(
    (key) => () => setActiveCards((state) => (state.includes(key) ? state.filter((e) => e !== key) : [...state, key])),
    []
  );
  return faqlist.slice(slice).map((faq, index) => {
    return (
      <div className={classNames(`faq-card box-shadow-black-25`)} key={index}>
        <div
          className={classNames(
            `faq-card-header faq-card-header-${
              activeCards.includes(index) ? 'open' : 'closed'
            } d-flex align-items-center justify-content-between`
          )}
          onClick={onClick(index)}
        >
          <div className="faq-card-header-text">
            <ReactMarkdown className="faq-card-header-title" source={faq.question || 'faq question'} />
          </div>
          <div className="faq-card-header-icon">
            <FontAwesomeIcon className="faq-card-icon" icon={activeCards.includes(index) ? faMinus : faPlus} />
          </div>
        </div>
        <Collapse isOpen={activeCards.includes(index)}>
          <div className="faq-card-content">
            <ReactMarkdown source={faq.answer || 'faq answer'} />
          </div>
        </Collapse>
      </div>
    );
  });
}
