import React from 'react';
import {FacebookShareButton, TwitterShareButton, PinterestShareButton} from 'react-share';
import {faFacebookF} from '@fortawesome/free-brands-svg-icons/faFacebookF';
import {faXTwitter} from '@fortawesome/free-brands-svg-icons/faXTwitter';
import {faPinterestP} from '@fortawesome/free-brands-svg-icons/faPinterestP';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import './ShareButtons.scss';

export default function ShareButtons(props) {
  return (
    <>
      {props.floating ? (
        <div className="social-share social-share-floating">
          <ShareButtonsList {...props} />
        </div>
      ) : (
        <>
          {props.shareText ? (
            <div className="social-share-copy d-flex align-items-center justify-content-center">
              <div className="social-share-text mb-2 mb-sm-0 mr-sm-2">Share on social:</div>
              <div className="social-share d-flex align-items-center justify-content-center">
                <ShareButtonsList {...props} noDescription />
              </div>
            </div>
          ) : (
            <div className={classNames('social-share d-flex align-items-center', props.alignment)}>
              <ShareButtonsList {...props} noDescription />
            </div>
          )}
        </>
      )}
    </>
  );
}

ShareButtons.defaultProps = {
  alignment: 'justify-content-center justify-content-lg-end'
};

export function ShareButtonsList({
  shareUrl = 'https://thriving.foodrevolution.org/masterclass/',
  shareImg = 'https://cdn.foodrevolution.org/ppt/social/ppt-ogimage-v20191018.jpg',
  facebookDescription = 'John Robbins is one of the world’s most beloved natural health experts and inspired millions of people to claim the power of their food choices to take a stand for a healthy life. Right now, you have a very special limited-time opportunity to join John for a totally free Masterclass on how you can make food the foundation of YOUR health. Click here to join him...',
  twitterDescription = 'Find out about 10 mighty #plantpowered breakthroughs that could save millions of lives in this free #masterclass with world-renowned author, John Robbins. Join the FREE #foodforhealthmasterclass here:',
  twitterHashtags = [null],
  twitterImg = 'https://cdn.foodrevolution.org/ppt/social/ppt-ogimage-v20191018.jpg',
  pinterestDescription = 'Looking for a sign that it’s time to take charge of your healthy-eating habits? This is it. Join this free masterclass and get the inspiration you need to make your healthy habits stick. Watch here now: https://thriving.foodrevolution.org/masterclass/',
  pinterestImg = 'https://cdn.foodrevolution.org/ppt/social/ppt-ogimage-v20191018.jpg',
  noDescription = false,
  ...props
}) {
  return (
    <>
      {noDescription ? null : (
        <div className="social-button">
          <div className="btn btn-social btn-social-share">Shares</div>
        </div>
      )}

      <FacebookShareButton className="social-button" url={shareUrl} quote={facebookDescription}>
        <div className="btn btn-social btn-social-facebook">
          <FontAwesomeIcon className="social-button-icon" icon={faFacebookF} />
          <span className="btn-social-description btn-social-description-facebook d-none d-md-inline">Facebook</span>
        </div>
      </FacebookShareButton>

      <TwitterShareButton
        className="social-button"
        url={shareUrl}
        media={twitterImg}
        via="afoodrevolution"
        hashtags={twitterHashtags}
        title={twitterDescription}
      >
        <div className="btn btn-social btn-social-twitter">
          <FontAwesomeIcon className="social-button-icon" icon={faXTwitter} />
          <span className="btn-social-description btn-social-description-twitter d-none d-md-inline">Twitter</span>
        </div>
      </TwitterShareButton>

      <PinterestShareButton
        className="social-button"
        url={shareUrl}
        media={pinterestImg}
        description={pinterestDescription}
      >
        <div className="btn btn-social btn-social-pinterest">
          <FontAwesomeIcon className="social-button-icon" icon={faPinterestP} />
          <span className="btn-social-description btn-social-description-pinterest d-none d-md-inline">Pinterest</span>
        </div>
      </PinterestShareButton>
    </>
  );
}
