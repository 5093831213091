import React from 'react';
import {FormFeedback, Input} from 'reactstrap';

export default function({children, field: {onBlur, onChange, name, value}, form: {touched, errors}, ...props}) {
  return (
    <>
      <Input
        invalid={!!(touched[name] && errors[name])}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        type="select"
        {...props}
      >
        {children}
      </Input>
      {touched[name] && errors[name] && <FormFeedback>{errors[name]}</FormFeedback>}
    </>
  );
}
