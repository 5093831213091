import React, {useCallback, useState} from 'react';
import {Button, Container, Col, Row} from 'reactstrap';

import Modal from 'components/Modal';
import ModalContext from 'contexts/modal';
import CheckoutButton from 'components/CheckoutButton';
import classNames from 'classnames';
import ShowIf from 'funnel-schedule/ShowIf';
import useExitIntent from 'utils/useExitIntent';
import HideIf from 'funnel-schedule/HideIf';
import {faCartPlus} from '@fortawesome/pro-light-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {usePhaseState} from 'funnel-schedule/phase-context';

export default function ModalExitSales({...props}) {
  const {phases} = usePhaseState() || {};
  const isCartClose = phases.default && phases.default.phase === 'cartclose';
  const {isOpen, toggle} = useExitIntent(isCartClose ? 60000 : 30000);

  return (
    <Modal className="modal-exit-intent" isOpen={isOpen} toggle={toggle}>
      <Row className="text-center m-0">
        <Col xs="12" className="order-lg-2 py-5 my-auto">
          <Row className="d-flex justify-content-center">
            <Col xs="12" sm="11" md="12" lg="10" xl="8">
              <h3 className="section-heading">
                Before you go, did you know you can get a special 40% discount right on this page?
              </h3>
            </Col>
          </Row>

          <Row className="d-flex justify-content-center">
            <Col xs="12" md="10" lg="8">
              <img className="img-fluid" src="https://cdn.foodrevolution.org/ppt/ppt-product-image.png" />
            </Col>
          </Row>

          <ShowIf phase="cartclose">
            <Row>
              <Col>
                <p className="mt-4 mb-0">TODAY ONLY. Don’t miss out!</p>
              </Col>
            </Row>
          </ShowIf>

          <Row className="d-flex justify-content-center">
            <Col xs="12" md="6">
              <Button className="btn-exit text-uppercase text-white text-h5" color="cta" onClick={toggle} block>
                Learn More Now
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Modal>
  );
}
