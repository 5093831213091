import React, {useCallback, useState} from 'react';
import queryString from 'query-string';
import {Button} from 'reactstrap';
import classNames from 'classnames';
import {useUserState} from 'contexts/user';
import './CheckoutButton.scss';
import track from 'utils/track';
const {CHECKOUT_URL} = process.env;

export default function CheckoutButton({children, sku, slug, price, queryParams = {}, ...props}) {
  const userState = useUserState();
  const [loading, setLoading] = useState(false);
  const {location: {search = null} = {}} = typeof window === 'undefined' ? {} : window;
  if (search) {
    const searchParams = queryString.parse(search);
    ['orid', 'opid'].map((k) => searchParams[k] && (queryParams[k] = searchParams[k]));
  }
  const queryParamString = queryString.stringify(queryParams);
  const href = `${CHECKOUT_URL}/${slug}/${queryParamString ? `?${queryParamString}` : ''}`;
  const onClick = useCallback((e) => {
    const {href, target} = e.target;
    const openInNewWindow = target && target === '_blank';
    if (!openInNewWindow) {
      e.preventDefault();
    }
    const cb = () => !openInNewWindow && (window.location.href = href);
    setLoading(true);
    setTimeout(cb, 500);
    track(
      'Product Added',
      {
        sku,
        product_id: sku,
        price,
        quantity: 1
      },
      null,
      cb
    );
  }, []);

  return (
    <Button className={props.className} color="cta" size="lg" {...props} onClick={onClick} href={href}>
      {loading ? 'Loading...' : children || 'Buy Now'}
    </Button>
  );
}
