import React, {useEffect, useState} from 'react';
import axios from 'axios';
import queryString from 'query-string';
import {Form, Formik, Field} from 'formik';
import {DateTime} from 'luxon';
import {Alert, Button, FormGroup, ModalBody} from 'reactstrap';
import * as Yup from 'yup';

import {useUserDispatch, useUserState} from 'contexts/user';
import {submitForm} from 'utils/forms';
import CustomSelectField from 'components/OptInForm/components/CustomSelectField';
import {usePhaseState, usePhaseDispatch} from 'funnel-schedule/phase-context';
import MasterclassReregisterForm from 'components/MasterclassReregisterForm';
import SegmentationForm from 'components/OptInForm/components/SegmentationForm';
import {useModal} from 'contexts/modal';
import {navigate} from '@reach/router';
import Modal from 'components/Modal';

export default function MasterclassInfographicForm({
  id = 'masterclass-infographic-form',
  linkColor = 'purple',
  disableNavigate = false,
  fromInfographic = false,
  ...props
}) {
  const [step, setStep] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const [navigateOnClose, setNavigateOnClose] = React.useState(false);

  const modalToggle = () => setIsOpen((v) => !v);

  const onComplete = React.useCallback(() => {
    modalToggle();
    !disableNavigate && navigate('/masterclass/watch/');
  }, []);

  const onStepComplete = React.useCallback((step) => step === 1 && setNavigateOnClose(true), []);

  const toggle = React.useCallback(() => {
    if (navigateOnClose && !disableNavigate) navigate('/masterclass/watch/');
    modalToggle();
  }, [navigateOnClose]);

  return (
    <>
      {(() => {
        switch (step) {
          default:
          case 0:
            return (
              <MasterclassReregisterForm
                onComplete={() => {
                  setStep(1);
                  modalToggle();
                  onStepComplete(1);
                }}
                id={id}
                buttonTxt={
                  <>
                    Reserve Your <br className="d-none d-lg-block d-xl-none" />
                    Free Spot Now
                  </>
                }
                fromInfographic={fromInfographic}
              />
            );
          case 1:
            return (
              <Modal {...props} isOpen={isOpen} toggle={toggle} size="lg">
                <ModalBody className="px-4 pt-0 pb-4">
                  <SegmentationForm
                    onComplete={() => {
                      onComplete();
                      onStepComplete(2);
                    }}
                    id={id}
                  />
                </ModalBody>
              </Modal>
            );
        }
      })()}
    </>
  );
}
