import React from 'react';
import classNames from 'classnames';
import './Angle.scss';

export default function Angle({color = 'white', borderColor = 'white', ...props}) {
  return (
    <div className={classNames(`fill-angle-wrap`, props.angleWrapClass)}>
      <div className={classNames(`fill-angle fill-angle-bottom`, props.className)}>
        <svg className="fill-svg" viewBox="0 0 500 50" preserveAspectRatio="none">
          <polygon points="0,0 500,0 500,50 0,1" className={classNames(`fill-polygon fill-${color}`)}></polygon>
          <defs>
            <linearGradient id="gradient-indigo-purple-right">
              <stop offset="0" stopColor="#6f00a2" />
              <stop offset="1" stopColor="#7d00b5" />
            </linearGradient>
          </defs>
        </svg>
      </div>

      <div className={classNames(`fill-angle fill-angle-bottom fill-angle-bottom-border`, props.className)}>
        <svg className="fill-svg" viewBox="0 0 500 50" preserveAspectRatio="none">
          <polygon points="0,0 500,0 500,50 0,6" className={classNames(`fill-polygon fill-${borderColor}`)}></polygon>
          <defs>
            <linearGradient id="gradient-indigo-purple-right">
              <stop offset="0" stopColor="#6f00a2" />
              <stop offset="1" stopColor="#7d00b5" />
            </linearGradient>
          </defs>
        </svg>
      </div>
    </div>
  );
}

export function AngleTop({color = 'white', borderColor = 'white', ...props}) {
  return (
    <div className="fill-angle-wrap">
      <div className={classNames(`fill-angle fill-angle-top`, props.className)}>
        <svg className="fill-svg" viewBox="0 0 500 50" preserveAspectRatio="none">
          <polygon points="0,0 0,50 500,50 500,49" className={classNames(`fill-polygon fill-${color}`)}></polygon>
          <defs>
            <linearGradient id="gradient-indigo-purple-right">
              <stop offset="0" stopColor="#6f00a2" />
              <stop offset="1" stopColor="#7d00b5" />
            </linearGradient>
          </defs>
        </svg>
      </div>

      <div className={classNames(`fill-angle fill-angle-top fill-angle-top-border`, props.className)}>
        <svg className="fill-svg" viewBox="0 0 500 50" preserveAspectRatio="none">
          <polygon points="0,0 0,50 500,50 500,44" className={classNames(`fill-polygon fill-${borderColor}`)}></polygon>
          <defs>
            <linearGradient id="gradient-indigo-purple-right">
              <stop offset="0" stopColor="#6f00a2" />
              <stop offset="1" stopColor="#7d00b5" />
            </linearGradient>
          </defs>
        </svg>
      </div>
    </div>
  );
}
