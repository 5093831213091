import React from 'react';
import {Col, Container, Row} from 'reactstrap';
import classNames from 'classnames';
import './Guarantee.scss';

export default function Guarantee(props) {
  return (
    <div
      className={classNames(`guarantee-box rounded background-${props.bgColor} box-shadow-black-50`, props.className)}
    >
      <h2 className="section-heading guarantee-heading text-center text-sm-left">
        You’re Protected By Our <br className="d-none d-md-block d-xl-none" />
        60-Day, Unconditional <br className="d-none d-md-block d-xl-none" />
        Money-Back Guarantee
      </h2>
      <p>
        <strong>Try it out.</strong> If you aren't completely blown away by the course, even if you just don’t like it,
        just email us at{' '}
        <a className="link-black text-underline" href="mailto:support@foodrevolution.org">
          support@foodrevolution.org
        </a>
        , any time within 60 days after purchase to ask for a prompt and courteous refund. This means you can even take
        the whole course, take every lesson, and make every single mouth-watering recipe, and if it doesn’t rock your
        world, you get every penny back. That’s how confident we are that you’ll LOVE Plant-Powered & Thriving!
      </p>
      <p>
        PLUS, if for any reason you request a refund, the entire Plant-Powered & Thriving course — everything that
        you’ve downloaded — is still yours to keep. That’s why we call our guarantee “better than money back.”
      </p>
    </div>
  );
}

Guarantee.defaultProps = {
  bgColor: 'white',
  borderColor: 'light-gray'
};

export function GuaranteeBox({title, children, ...props}) {
  return (
    <div className={classNames(`guarantee-box rounded background-white box-shadow-black-50`, props.className)}>
      <div className="text-center text-sm-left">{title}</div>
      {children}
    </div>
  );
}
