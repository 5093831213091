import React from 'react';
import MasterclassIndex from './masterclass/index';

export default function () {
  return (
    <MasterclassIndex
      fromEntry="plants"
      slug="plants"
      pageTitle="Guide to Going Plant-Based"
      pageDescription="A plant-based diet can be a great way to prevent Alzheimer’s, heart disease, and T2D — and help save the planet! But you have to know how to do it right. Get Food Revolution Network’s Guide To Going Plant-based (for FREE)!"
      shareUrl="https://thriving.foodrevolution.org/plants/"
      shareImg="https://cdn.foodrevolution.org/ppt/social/ppt-open_graph-Plantbased-facebook-v20220125.png"
      facebookDescription="Get Food Revolution Network’s Guide To Going Plant-based (for FREE), PLUS get a free spot in the Food For Health Masterclass with John Robbins."
      twitterDescription="Get Food Revolution Network’s Guide To Going Plant-based (for FREE), PLUS get a free spot in the Food For Health Masterclass with John Robbins."
      twitterImg="https://cdn.foodrevolution.org/ppt/social/ppt-open_graph-Plantbased-twitter-v20220125.png.png"
      pinterestDescription="Get Food Revolution Network’s Guide To Going Plant-based (for FREE), PLUS get a free spot in the Food For Health Masterclass with John Robbins."
      pinterestImg="https://cdn.foodrevolution.org/ppt/social/ppt-open_graph-Plantbased-pinterest-v20220125.png"
    />
  );
}
