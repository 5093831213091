import React from 'react';
import {Button, Modal, ModalHeader, ModalBody} from 'reactstrap';
import useModal from 'contexts/modal';
import ReactDOM from 'react-dom';
import './Modal.scss';

export default function WiredModal({children, isOpen, toggle, ...props}) {
  return (
    <Modal isOpen={isOpen} toggle={toggle} returnFocusAfterClose={false} {...props}>
      <ModalHeader toggle={toggle} />
      <ModalBody>{children}</ModalBody>
    </Modal>
  );
}
