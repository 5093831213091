import React, {Component, useCallback, useState} from 'react';
import {Row, Col, Collapse} from 'reactstrap';
import ReactMarkdown from 'react-markdown';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAngleDown} from '@fortawesome/pro-regular-svg-icons/faAngleDown';
import {faAngleUp} from '@fortawesome/pro-regular-svg-icons/faAngleUp';
import {modules, modulesV1} from 'data/module.json';
import './ModuleAccordion.scss';
const remarkSubSuper = require('remark-sub-super');

export default function ModuleAccordion(props) {
  const [activeCards, setActiveCards] = React.useState([]);
  const onClick = React.useCallback(
    (key) => () => setActiveCards((state) => (state.includes(key) ? state.filter((e) => e !== key) : [...state, key])),
    []
  );
  return modules.map((module, index) => {
    return (
      <div className="module-item" key={index}>
        <div className="module-item-header d-flex align-items-center justify-content-between" onClick={onClick(index)}>
          <div className="module-item-header-text">
            <Row className="bonus-heading d-flex align-items-center justify-content-start m-0">
              <Col xs="12" md="3" lg="2" className="text-center mb-3 mb-md-0 pl-lg-0">
                <img className="module-img" src={module.thumb} />
              </Col>
              <Col xs="12" md="9" lg="10">
                <div className="module-item-count">{module.module}</div>
                <div className="module-item-title">{module.name}</div>
              </Col>
            </Row>
          </div>
          <div className="module-item-header-icon d-flex align-items-center justify-content-end flex-column flex-lg-row">
            <FontAwesomeIcon
              className="module-item-icon mb-1 mb-lg-0 mr-lg-1"
              icon={activeCards.includes(index) ? faAngleUp : faAngleDown}
            />
            <div className="module-item-header-icon-text text-small text-uppercase">
              {activeCards.includes(index) ? (
                <>Close</>
              ) : (
                <>
                  <span className="d-none d-lg-inline">Learn</span> More
                </>
              )}
            </div>
          </div>
        </div>
        <Collapse isOpen={activeCards.includes(index)}>
          <div className="module-item-content">
            {props.images ? (
              <Row className="d-flex justify-content-center align-items-center">
                <Col xs="12" lg="3" xl="2" className="text-center mb-3 mb-lg-0">
                  <img className="module-item-descrption-image img-fluid mx-auto" src={module.moduleImg} />
                </Col>
                <Col xs="12" lg="9" xl="10">
                  <ReactMarkdown className="module-item-description" source={module.description} />
                </Col>
              </Row>
            ) : (
              <Row>
                <Col xs="12">
                  <ReactMarkdown className="module-item-description" source={module.description} />
                </Col>
              </Row>
            )}

            {module.guestName ? (
              <>
                <h5>With Guest Faculty:</h5>
                {props.images ? (
                  <Row className="d-flex justify-content-center align-items-center">
                    <Col xs="12" lg="3" xl="2" className="text-center mb-3 mb-lg-0">
                      <img className="module-item-guest-image img-fluid rounded" src={module.guestImg} />
                    </Col>
                    <Col xs="12" lg="9" xl="10">
                      <p className="module-item-guest">
                        {module.guestName}
                        <br />
                        <em>{module.guestTitle}</em>
                      </p>
                    </Col>
                  </Row>
                ) : (
                  <Row>
                    <Col xs="12">
                      <p className="module-item-guest">
                        {module.guestName}
                        <br />
                        <em>{module.guestTitle}</em>
                      </p>
                    </Col>
                  </Row>
                )}
              </>
            ) : null}

            <h5>Free Special Report:</h5>
            {props.images ? (
              <Row className="d-flex justify-content-center align-items-center">
                <Col xs="12" lg="3" xl="2" className="text-center mb-3 mb-lg-0">
                  <img className="module-item-report-image img-fluid mx-auto" src={module.reportImg} />
                </Col>
                <Col xs="12" lg="9" xl="10">
                  <ReactMarkdown className="module-item-report" source={module.specialReport} />
                </Col>
              </Row>
            ) : (
              <Row>
                <Col xs="12">
                  <ReactMarkdown className="module-item-report" source={module.specialReport} />
                </Col>
              </Row>
            )}

            <h5>Module Recipes Theme:</h5>
            <p className="module-item-recipe">{module.recipeTheme}</p>

            <h5>Chef Cynthia Cooking Demonstration Video:</h5>
            {props.images ? (
              <Row className="d-flex justify-content-center align-items-center">
                <Col xs="12" lg="3" xl="2" className="text-center mb-3 mb-lg-0">
                  <img className="module-item-video-image img-fluid rounded" src={module.videoImg} />
                </Col>
                <Col xs="12" lg="9" xl="10">
                  <p className="module-item-video">{module.demoVideo}</p>
                </Col>
              </Row>
            ) : (
              <Row>
                <Col xs="12">
                  <p className="module-item-video mb-0">{module.demoVideo}</p>
                </Col>
              </Row>
            )}
          </div>
        </Collapse>
      </div>
    );
  });
}

export function ModuleAccordionExpanded(props) {
  return modules.map((module, index) => {
    return (
      <div className="module-item" key={index}>
        <div className="module-item-header module-item-header-v1 d-flex align-items-center justify-content-between">
          <div className="module-item-header-text">
            <Row className="bonus-heading d-flex align-items-center justify-content-start m-0">
              <Col xs="12" md="3" lg="2" className="text-center mb-3 mb-md-0 pl-lg-0">
                <img className="module-img" src={module.thumb} />
              </Col>
              <Col xs="12" md="9" lg="10">
                <div className="module-item-count">{module.module}</div>
                <div className="module-item-title">{module.name}</div>
              </Col>
            </Row>
          </div>
        </div>

        <div className="module-item-content">
          {props.images ? (
            <Row className="d-flex justify-content-center align-items-center">
              <Col xs="12" lg="3" xl="2" className="text-center mb-3 mb-lg-0">
                <img className="module-item-descrption-image img-fluid mx-auto" src={module.moduleImg} />
              </Col>
              <Col xs="12" lg="9" xl="10">
                <ReactMarkdown className="module-item-description" source={module.description} />
              </Col>
            </Row>
          ) : (
            <Row>
              <Col xs="12">
                <ReactMarkdown className="module-item-description" source={module.description} />
              </Col>
            </Row>
          )}

          {module.guestName ? (
            <>
              <h5>With Guest Faculty:</h5>
              {props.images ? (
                <Row className="d-flex justify-content-center align-items-center">
                  <Col xs="12" lg="3" xl="2" className="text-center mb-3 mb-lg-0">
                    <img className="module-item-guest-image img-fluid rounded" src={module.guestImg} />
                  </Col>
                  <Col xs="12" lg="9" xl="10">
                    <p className="module-item-guest">
                      {module.guestName}
                      <br />
                      <em>{module.guestTitle}</em>
                    </p>
                  </Col>
                </Row>
              ) : (
                <Row>
                  <Col xs="12">
                    <p className="module-item-guest">
                      {module.guestName}
                      <br />
                      <em>{module.guestTitle}</em>
                    </p>
                  </Col>
                </Row>
              )}
            </>
          ) : null}

          <h5>Special Reports:</h5>
          {props.images ? (
            <Row className="d-flex justify-content-center align-items-center">
              <Col xs="12" lg="3" xl="2" className="text-center mb-3 mb-lg-0">
                <img className="module-item-report-image img-fluid mx-auto" src={module.reportImg} />
              </Col>
              <Col xs="12" lg="9" xl="10">
                <ReactMarkdown className="module-item-report" source={module.specialReport} />
              </Col>
            </Row>
          ) : (
            <Row>
              <Col xs="12">
                <ReactMarkdown className="module-item-report" source={module.specialReport} />
              </Col>
            </Row>
          )}

          <h5>{module.module} Recipes Theme:</h5>
          <p className="module-item-recipe">{module.recipeTheme}</p>

          <h5>Chef Cynthia Cooking Demonstration Video:</h5>
          {props.images ? (
            <Row className="d-flex justify-content-center align-items-center">
              <Col xs="12" lg="3" xl="2" className="text-center mb-3 mb-lg-0">
                <img className="module-item-video-image img-fluid rounded" src={module.videoImg} />
              </Col>
              <Col xs="12" lg="9" xl="10">
                <p className="module-item-video">{module.demoVideo}</p>
              </Col>
            </Row>
          ) : (
            <Row>
              <Col xs="12">
                <p className="module-item-video mb-0">{module.demoVideo}</p>
              </Col>
            </Row>
          )}
        </div>
      </div>
    );
  });
}

export function ModuleAccordionV1(props) {
  return modulesV1.map((module, index) => {
    return (
      <div className="module-item" key={index}>
        <div className="module-item-header module-item-header-v1 d-flex align-items-center justify-content-between">
          <div className="module-item-header-text">
            <Row className="bonus-heading d-flex align-items-center justify-content-start m-0">
              <Col xs="12" md="3" lg="2" className="text-center mb-3 mb-md-0 pl-lg-0">
                <img className="module-img" src={module.thumb} />
              </Col>
              <Col xs="12" md="9" lg="10">
                <div className="module-item-count">{module.module}</div>
                <div className="module-item-title">{module.name}</div>
              </Col>
            </Row>
          </div>
        </div>

        <div className="module-item-content">
          {props.images ? (
            <Row className="d-flex justify-content-center align-items-center">
              <Col xs="12" lg="3" xl="2" className="text-center mb-3 mb-lg-0">
                <img className="module-item-descrption-image img-fluid mx-auto" src={module.moduleImg} />
              </Col>
              <Col xs="12" lg="9" xl="10">
                <ReactMarkdown className="module-item-description" source={module.description} />
              </Col>
            </Row>
          ) : (
            <Row>
              <Col xs="12">
                <ReactMarkdown className="module-item-description" source={module.description} />
              </Col>
            </Row>
          )}

          {module.guestName ? (
            <>
              <h5>With Guest Faculty:</h5>
              {props.images ? (
                <Row className="d-flex justify-content-center align-items-center">
                  <Col xs="12" lg="3" xl="2" className="text-center mb-3 mb-lg-0">
                    <img className="module-item-guest-image img-fluid rounded" src={module.guestImg} />
                  </Col>
                  <Col xs="12" lg="9" xl="10">
                    <p className="module-item-guest">
                      {module.guestName}
                      <br />
                      <em>{module.guestTitle}</em>
                    </p>
                  </Col>
                </Row>
              ) : (
                <Row>
                  <Col xs="12">
                    <p className="module-item-guest">
                      {module.guestName}
                      <br />
                      <em>{module.guestTitle}</em>
                    </p>
                  </Col>
                </Row>
              )}
            </>
          ) : null}

          <h5>Special Reports:</h5>
          {props.images ? (
            <Row className="d-flex justify-content-center align-items-center">
              <Col xs="12" lg="3" xl="2" className="text-center mb-3 mb-lg-0">
                <img className="module-item-report-image img-fluid mx-auto" src={module.reportImg} />
              </Col>
              <Col xs="12" lg="9" xl="10">
                <ReactMarkdown className="module-item-report" source={module.specialReport} />
              </Col>
            </Row>
          ) : (
            <Row>
              <Col xs="12">
                <ReactMarkdown className="module-item-report" source={module.specialReport} />
              </Col>
            </Row>
          )}

          <h5>{module.module} Recipes Theme:</h5>
          <p className="module-item-recipe">{module.recipeTheme}</p>

          <h5>Chef Cynthia Cooking Demonstration Video:</h5>
          {props.images ? (
            <Row className="d-flex justify-content-center align-items-center">
              <Col xs="12" lg="3" xl="2" className="text-center mb-3 mb-lg-0">
                <img className="module-item-video-image img-fluid rounded" src={module.videoImg} />
              </Col>
              <Col xs="12" lg="9" xl="10">
                <p className="module-item-video">{module.demoVideo}</p>
              </Col>
            </Row>
          ) : (
            <Row>
              <Col xs="12">
                <p className="module-item-video mb-0">{module.demoVideo}</p>
              </Col>
            </Row>
          )}
        </div>
      </div>
    );
  });
}

export function ModuleList({list = modules, ...props}) {
  const [activeCards, setActiveCards] = React.useState([0]);
  const onClick = React.useCallback(
    (key) => () => setActiveCards((state) => (state.includes(key) ? state.filter((e) => e !== key) : [...state, key])),
    []
  );
  return list.map((module, index) => {
    return (
      <div className="module-item" key={index}>
        <div className="module-item-heading d-flex align-items-center justify-content-between">
          <div className="module-item-header-text">
            <Row className="bonus-heading d-flex align-items-center justify-content-start m-0">
              <Col xs="12" className="px-3 px-lg-0">
                <div className="module-item-count">{module.module}</div>
                <div className="module-item-title">
                  Day {module.day}: {module.name}
                </div>
              </Col>
            </Row>
          </div>
        </div>

        <div className="module-item-content module-item-content-list px-3 px-lg-0">
          <ReactMarkdown
            className="module-item-list-description"
            source={module.description || 'description'}
            plugins={[remarkSubSuper]}
            renderers={{
              sub: 'sub',
              sup: 'sup'
            }}
          />
          {!module.recipe ? null : (
            <>
              {module.recipe2 ? (
                <>
                  <Row className="align-items-center justify-content-center justify-content-sm-start mt-3 mt-md-0">
                    <Col xs="12" md="9" lg="10" className="order-md-2 mb-3 mb-md-0">
                      <span className="text-800 text-uppercase">Daily Recipe</span>
                      <br />
                      {module.recipe}
                    </Col>
                    <Col xs="12" md="3" lg="2" className="text-center order-md-1">
                      <img className="rounded border" src={module.recipeImage} />
                    </Col>
                  </Row>
                  <Row className="align-items-center justify-content-center justify-content-sm-start mt-3">
                    <Col xs="12" md="9" lg="10" className="order-md-2 mb-3 mb-md-0">
                      <span className="text-800 text-uppercase">Daily Recipe</span>
                      <br />
                      {module.recipe2}
                    </Col>
                    <Col xs="12" md="3" lg="2" className="text-center order-md-1">
                      <img className="rounded border" src={module.recipe2Image} />
                    </Col>
                  </Row>
                </>
              ) : (
                <>
                  <Row className="align-items-center justify-content-center justify-content-sm-start mt-3 mt-md-0">
                    <Col xs="12" md="9" lg="10" className="order-md-2 mb-3 mb-md-0">
                      <span className="text-800 text-uppercase">Daily Recipe</span>
                      <br />
                      {module.recipe}
                    </Col>
                    <Col xs="12" md="3" lg="2" className="text-center order-md-1">
                      <img className="rounded border" src={module.recipeImage} />
                    </Col>
                  </Row>
                </>
              )}
            </>
          )}
        </div>
      </div>
    );
  });
}
