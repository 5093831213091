import React, {useCallback, useState} from 'react';
import {Button, Container, Col, Row, Collapse} from 'reactstrap';
import ReactMarkdown from 'react-markdown';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAngleDown} from '@fortawesome/pro-regular-svg-icons/faAngleDown';
import {faAngleUp} from '@fortawesome/pro-regular-svg-icons/faAngleUp';
import classNames from 'classnames';
import {instructors} from 'data/ppt.json';
import './InstructorCardList.scss';

export function InstructorCardList({borderColor = 'light-gray-100', ...props}) {
  const [activeCards, setActiveCards] = React.useState([]);
  const onClick = React.useCallback(
    (key) => () => setActiveCards((state) => (state.includes(key) ? state.filter((e) => e !== key) : [...state, key])),
    []
  );
  return instructors.map((instructor, index) => {
    return (
      <Col key={'instructor' + index} sm="10" lg="8" xl="4" className="mb-5 mb-xl-0 flex-grow-1 d-flex flex-column">
        <div
          className={classNames(`instructor-card`, `background-white`, `box-shadow-black-50`, `d-flex`, `flex-column`)}
        >
          <div className="text-center p-3">
            <img
              src={instructor.image.url}
              alt={instructor.image.alt}
              width={instructor.image.width}
              height={instructor.image.height}
            />
            <h5 className="instructor-card-name mt-3 mb-2">{instructor.name}</h5>
            <p className="instructor-card-tagline mb-0">{instructor.tagline}</p>
          </div>
          <div className="instructor-card-details">
            <div
              className="instructor-card-dropdown background-black text-white p-3 d-flex justify-content-between align-items-center"
              onClick={onClick(index)}
            >
              <div className="instructor-card-dropdown-text">
                Click Here to <br className="d-none d-xl-block" />
                <b>
                  Learn More About <br className="d-none d-sm-block" />
                  {instructor.name}
                </b>
              </div>
              <div className="instructor-card-icon text-h5">
                <FontAwesomeIcon icon={activeCards.includes(index) ? faAngleUp : faAngleDown} />
              </div>
            </div>
            <Collapse isOpen={activeCards.includes(index)}>
              <div className="p-3">
                <ReactMarkdown className="instructor-card-bio">{instructor.bio}</ReactMarkdown>
              </div>
            </Collapse>
          </div>
        </div>
      </Col>
    );
  });
}
