import React from 'react';
import {Col, Container, Row} from 'reactstrap';
import ReactMarkdown from 'react-markdown';
import CheckoutButton from 'components/CheckoutButton';
import CountdownTimer from '../CountdownTimer';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faLock, faCartPlus} from '@fortawesome/pro-light-svg-icons';
import './SalesCta.scss';
import logoTrees from 'static/logo/logo-trees-for-future-white.png';
import classNames from 'classnames';
import {useUserState} from 'contexts/user';
import {usePhaseState, usePhaseDispatch} from 'funnel-schedule/phase-context';

export function SalesDisclaimer({iconColor = 'green', ...props}) {
  return (
    <div className="disclaimer-wrap">
      <div className="disclaimer-cards">
        <img
          className="img-fluid img-cc"
          src="https://cdn.foodrevolution.org/global/images/payments-accepted-20240424.svg"
          width={1800}
          height={175}
        />
      </div>
      <div className="disclaimer-copy text-small">
        <p className={props.noGuaranteeText ? 'mb-0' : 'mb-2'}>
          <FontAwesomeIcon className={classNames(`offer-box-security-icon text-${iconColor} mr-2`)} icon={faLock} /> For
          your security, all orders are <br className="d-none d-sm-block d-md-none" />
          processed on a secured server.
        </p>
        {props.noGuaranteeText ? null : (
          <p className="mb-0">
            Your purchase is protected by our <br className="d-none d-sm-block d-md-none" />
            60-day money back guarantee.
          </p>
        )}
      </div>
    </div>
  );
}

export default function CtaSales(props) {
  const offerBox = classNames(
    'offer-box offer-box-shadow px-3 py-4 px-sm-4 px-md-5 py-md-5 text-center',
    props.offerBoxBackground,
    props.offerBoxBorder
  );

  const date = new Date(`${props.timerDate}`).getTime();

  return (
    <div className={classNames(`cta-sales cta-sales-${props.className}`)}>
      <div className={offerBox}>
        <Row className="d-flex justify-content-center">
          <Col xs="12" lg="8">
            <img className="img-fluid" src="https://cdn.foodrevolution.org/ppt/ppt-product-image.png" />
          </Col>
        </Row>

        {props.heading ? <div className="offer-box-heading">{props.heading}</div> : null}

        {props.offerPrice ? (
          <div className="offer-box-offer-text">
            {props.offerText} <del className="regular-price">${props.regularPrice}</del> ${props.offerPrice}
          </div>
        ) : (
          <div className="offer-box-regular-price">Your Price: ${props.regularPrice}</div>
        )}

        {props.timerText ? (
          <div className="offer-box-timer">
            <div className="offer-box-timer-text">{props.timerText}</div>
            <div className="countdown-dark">
              <CountdownTimer time={props.date} />
            </div>
          </div>
        ) : null}

        {props.offerPrice ? (
          <CheckoutButton className="offer-box-button" sku={props.sku} price={props.offerPrice} slug={props.slug}>
            <FontAwesomeIcon className="mr-2" icon={faCartPlus} /> Buy Now
          </CheckoutButton>
        ) : (
          <CheckoutButton className="offer-box-button" sku={props.sku} price={props.regularPrice} slug={props.slug}>
            <FontAwesomeIcon className="mr-2" icon={faCartPlus} /> Buy Now
          </CheckoutButton>
        )}

        <div className="offer-box-details">
          <div className="offer-box-cards">
            <img
              className="img-fluid img-cc"
              src="https://cdn.foodrevolution.org/global/images/payments-accepted-20240424.svg"
              width={1800}
              height={175}
            />
          </div>
          <div className="offer-box-security">
            <FontAwesomeIcon className="text-green mr-2" icon={faLock} />
            For your security, all orders are <br className="d-none d-sm-block d-md-none" />
            processed on a secured server.
          </div>
        </div>

        {props.paymentPlanText ? (
          <div className="offer-box-payment-plan">
            {props.paymentPlanText}
            <CheckoutButton
              color="link"
              className="btn-link-checkout ml-1"
              sku={props.sku}
              price={props.paymentPlanPrice}
              slug={props.slug}
              queryParams={{opt: 1}}
            >
              Join now for just ${props.paymentPlanPrice}/month!
            </CheckoutButton>
          </div>
        ) : null}
      </div>

      {props.noProtected ? null : (
        <Row className="offer-box-protected d-flex justify-content-center">
          <Col xs="10">
            <div className="text-white text-shadow text-center">
              Your purchase is protected by our <br className="d-sm-block d-lg-none" />
              60-day money back guarantee.
            </div>
          </Col>
        </Row>
      )}

      {props.noTrees ? null : (
        <Row className="trees-for-future text-white d-flex align-items-center justify-content-center mt-5">
          <Col xs="11" lg="7" className="mb-4 mb-lg-0">
            <h5 className="section-only-heading text-center text-lg-left">
              For every new order, we make a donation to Trees for the Future, enabling them to plant another organic
              fruit or nut tree in a low-income community.
            </h5>
          </Col>
          <Col xs="11" lg="3" className="text-center">
            <img className="logo-trees img-fluid" src={logoTrees} />
          </Col>
        </Row>
      )}
    </div>
  );
}

CtaSales.defaultProps = {
  offerBoxBackground: 'background-white',
  offerBoxBorder: 'border-dashed border-green border-2'
};

export function SalesCta247(props) {
  return (
    <CtaSales
      className="offer-247"
      regularPrice="497"
      offerPrice="247"
      heading={
        <>
          Get instant access — even <br className="d-none d-sm-block d-lg-none" />
          at 2 am – guaranteed!
        </>
      }
      offerText={
        <>
          Masterclass Special <br className="d-none d-sm-block d-xl-none" />
          Just
        </>
      }
      sku="C-PPT"
      slug="ppt-masterclass-wlc-30daytrial"
      paymentPlanText="Prefer a 3-month payment plan? We’ve got you covered!"
      paymentPlanPrice="89"
      {...props}
    />
  );
}

export function SalesCta297(props) {
  return (
    <CtaSales
      className="offer-297"
      regularPrice="497"
      offerPrice="297"
      heading={
        <>
          Get instant access — even <br className="d-none d-sm-block d-lg-none" />
          at 2 am – guaranteed!
        </>
      }
      offerText={
        <>
          Right Now <br className="d-none d-sm-block d-lg-none" />
          Just
        </>
      }
      sku="C-PPT"
      slug="ppt-special-wlc-30daytrial"
      paymentPlanText="Prefer a 3-month payment plan? We’ve got you covered!"
      paymentPlanPrice="109"
      {...props}
    />
  );
}

export function SalesCta297NoTimer(props) {
  return (
    <CtaSales
      className="offer-297"
      regularPrice="497"
      offerPrice="297"
      heading={
        <>
          Get instant access — even <br className="d-none d-sm-block d-lg-none" />
          at 2 am – guaranteed!
        </>
      }
      offerText={
        <>
          Right Now <br className="d-none d-sm-block d-lg-none" />
          Just
        </>
      }
      sku="C-PPT"
      slug="ppt-special-wlc-30daytrial"
      paymentPlanText="Prefer a 3-month payment plan? We’ve got you covered!"
      paymentPlanPrice="109"
      {...props}
    />
  );
}

export function SalesCta497(props) {
  return (
    <CtaSales
      className="offer-497"
      regularPrice="497"
      heading={
        <>
          Get instant access — even <br className="d-none d-sm-block d-lg-none" />
          at 2 am – guaranteed!
        </>
      }
      sku="C-PPT"
      slug="ppt"
      paymentPlanText="Prefer a 3-month payment plan? We’ve got you covered!"
      paymentPlanPrice="179"
      {...props}
    />
  );
}

// Dynamic Sales Box For User Experience
export function DynamicSalesCta({context = 'webinar', ...props}) {
  const {email, registrationId} = useUserState();
  const phases = usePhaseState().phases || {};
  const schedule = phases[context] || {};
  const {phase, pos, time, cartOpenPeriod} = schedule;
  const optinTime = time * 1000;
  const cartOpenTime = cartOpenPeriod * 1000;
  return phase === 'after' || (!email && !registrationId) ? (
    <SalesCta497 {...props} />
  ) : (
    <SalesCta297 date={optinTime + cartOpenTime} timerText="Your discount expires in:" {...props} />
  );
}

// PPT Alumni WLC Sales
export function SalesBox({
  offerBoxBackground = 'white',
  offerBoxBorder = 'border-dashed border-color-green-100 border-2',
  btnText = 'Buy Now',
  imgSrc = 'https://cdn.foodrevolution.org/hbc/hbc-product_image.png',
  imgWidth = '1200',
  imgHeight = '500',
  imgAlt = 'Healthy Brain graphic',
  ...props
}) {
  return (
    <div className={classNames(`cta-sales cta-sales-${props.className}`)}>
      {props.title ? <h2 className="section-heading text-white text-center">{props.title}</h2> : null}
      {props.subTitle ? <p className="text-white text-center mb-4">{props.subTitle}</p> : null}

      <div
        className={classNames(
          `offer-box offer-box-shadow px-3 py-4 px-sm-4 px-md-5 py-md-5 text-center background-${offerBoxBackground} ${offerBoxBorder}`
        )}
      >
        {props.noImage ? null : (
          <Row className="d-flex justify-content-center">
            <Col xs="10" md="8" xl="6">
              <img src={imgSrc} width={imgWidth} height={imgHeight} alt={imgAlt} />
            </Col>
          </Row>
        )}

        {props.heading ? (
          <ReactMarkdown
            className="offer-box-heading"
            source={props.heading || 'Get instant access — even at 2 am – guaranteed!'}
            skipHtml={true}
          />
        ) : null}

        {props.offerTextNoPrice ? (
          <div className="offer-box-offer">
            <div className="text-h3 text-red text-800 mt-4">{props.regularTextNoPrice}</div>
            <div className="offer-box-offer-text mt-3">{props.offerTextNoPrice}</div>
          </div>
        ) : (
          <>
            {props.offerPrice ? (
              <>
                {props.offerTextInline ? (
                  <div className="offer-box-offer-text-inline">
                    {props.offerTextInline} <del className="regular-price">${props.regularPrice}</del>{' '}
                    <span className="offer-price">${props.offerPrice}</span>
                  </div>
                ) : (
                  <div className="offer-box-offer">
                    <div className="text-h3 text-green text-800">
                      All For Just:{' '}
                      <span className="offer-box-offer-text-regular text-medium-gray">${props.regularPrice}</span>
                    </div>
                    <div className="offer-box-offer-text">
                      {props.offerText} ${props.offerPrice}
                    </div>
                  </div>
                )}
              </>
            ) : (
              <div className="offer-box-regular-price">
                <span className="text-black">Right Now:</span> ${props.regularPrice}
              </div>
            )}
          </>
        )}

        {props.timerText ? (
          <div className="offer-box-timer">
            <div className="offer-box-timer-text">{props.timerText}</div>
            <div className="countdown-dark">
              <CountdownTimer time={props.date} />
            </div>
          </div>
        ) : null}

        <CheckoutButton
          className="offer-box-button"
          sku={props.sku}
          price={props.offerPrice ? props.offerPrice : props.price}
          slug={props.slug}
        >
          <FontAwesomeIcon className="mr-2" icon={faCartPlus} /> {btnText}
        </CheckoutButton>

        <div className="offer-box-details">
          <div className="offer-box-cards">
            <img
              className="img-fluid img-cc"
              src="https://cdn.foodrevolution.org/global/images/payments-accepted-20240424.svg"
              width={1800}
              height={175}
            />
          </div>
          <div className="offer-box-security">
            <FontAwesomeIcon className="offer-box-security-icon mr-2" icon={faLock} />
            For your security, all orders are processed on a secured server.
          </div>
          <div className="offer-box-protected d-flex justify-content-center">
            Your purchase is protected by our 60-day money back guarantee.
          </div>
        </div>

        {props.paymentPlanPrice ? (
          <div className="offer-box-payment-plan mt-3">
            Prefer a 3-month payment plan? We’ve got you covered!
            <CheckoutButton
              color="link"
              className="btn-link-checkout ml-1"
              sku={props.sku}
              price={props.paymentPlanPrice}
              slug={props.slug}
              queryParams={{opt: 1}}
            >
              Join now for just ${props.paymentPlanPrice}/month!
            </CheckoutButton>
          </div>
        ) : null}
      </div>

      {props.trees ? (
        <Row className="trees-for-future text-white d-flex align-items-center justify-content-center mt-5">
          <Col xs="11" lg="8" className="mb-4 mb-lg-0">
            <h6 className="section-only-heading text-center text-lg-left text-400">
              For every new order, we make a donation to Trees for the Future, enabling them to plant another organic
              fruit or nut tree in a low-income community.
            </h6>
          </Col>
          <Col xs="11" lg="3" className="text-center">
            <img className="logo-trees" src={logoTrees} />
          </Col>
        </Row>
      ) : null}
    </div>
  );
}

export function SalesWlcAlumni(props) {
  return (
    <SalesBox
      className="wlc-ppt-alumni-127"
      imgSrc="https://wlc.foodrevolution.org/assets/images/action-collection.png"
      regularPrice="247"
      offerPrice="127"
      sku="wlc"
      slug="wlc-ppt-alumni"
      regularTextNoPrice={
        <>
          Regular price: $29/month <br className="d-none d-md-block" />
          (that’s $348 annually) or $247/year
        </>
      }
      offerTextNoPrice={
        <>
          Special Plant-Powered & Thriving Offer: <br className="d-none d-lg-block" />
          $19/month or $127/year!
        </>
      }
      {...props}
    />
  );
}

export function PurchaseCard({
  title = <h3 className="section-only-heading">Get Started Now!</h3>,
  btnText = 'Buy Now',
  ...props
}) {
  return (
    <div className="purchase-card">
      <div className="purchase-card-header background-black text-white rounded-top p-3 p-lg-4">
        <div className="purchase-card-title text-uppercase text-center m-0">{title}</div>
      </div>
      <div className="purchase-card-content background-white rounded-bottom px-4 px-lg-5 py-4">
        {props.heading ? <div className="purchase-card-heading">{props.heading}</div> : null}
        {props.copy ? <div className="purchase-card-copy">{props.copy}</div> : null}
        {props.timerText ? (
          <div className="offer-box-timer text-center mt-4">
            <div className="offer-box-timer-text">{props.timerText}</div>
            <div className="countdown-dark">
              <CountdownTimer time={props.date} />
            </div>
          </div>
        ) : null}
        <div className="text-center">
          <CheckoutButton
            className="offer-box-button"
            sku={props.sku}
            price={props.offerPrice ? props.offerPrice : props.price}
            slug={props.slug}
          >
            <FontAwesomeIcon className="mr-2" icon={faCartPlus} /> {btnText}
          </CheckoutButton>
          <SalesDisclaimer />
        </div>
      </div>
    </div>
  );
}
