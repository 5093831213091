import React, {useRef, useCallback, useEffect, useState} from 'react';
import {Col, Container, Row, Button} from 'reactstrap';
import {usePhaseState} from 'funnel-schedule/phase-context';
import useFixedFloating from 'utils/useFixedFloating';
import {OptinModalTrigger} from 'components/OptinModal';
import CheckoutButton from 'components/CheckoutButton';
import classNames from 'classnames';
import './HeaderVideo.scss';
import Video from 'components/Video';

export default function HeaderVideo({
  thumbnail,
  headline,
  noFixed,
  label,
  bgColor = 'black',
  salesBtnText = 'Buy Now',
  ...props
}) {
  const videoWrapper = useRef(null);
  const [isFixed, disableFixedListener] = useFixedFloating(videoWrapper);
  const [playing, setPlaying] = useState(props.playing === true);
  const dismiss = useCallback(() => {
    setPlaying(false);
    return disableFixedListener();
  }, []);

  useEffect(() => {
    if (noFixed) return disableFixedListener();
  }, [noFixed]);

  const onPlay = useCallback(() => setPlaying(true), []);

  useEffect(() => {
    isFixed ? document.body.classList.add('has-fixed-header') : document.body.classList.remove('has-fixed-header');
  }, [isFixed]);

  const {
    phases: {
      default: defaultSchedule = {
        phase: 'before'
      }
    }
  } = usePhaseState();

  return (
    <div className={classNames(`header-video-wrapper`, props.className)} ref={videoWrapper}>
      <div className="header-video-inner">
        <div className={isFixed ? 'header-video-fixed' : 'header-video-start'}>
          {isFixed ? (
            <Button
              color={props.closeBtnDark ? 'header-video-close btn-header-video-close-dark' : 'header-video-close'}
              onClick={dismiss}
            >
              <span aria-hidden="true">×</span>
            </Button>
          ) : null}

          <div
            className={classNames(
              `${
                isFixed ? 'header-video-background header-video-fixed-background' : 'header-video-background'
              } background-${bgColor}`
            )}
          >
            <div className={isFixed ? 'container' : null}>
              <div className={isFixed ? 'row d-flex align-items-center' : null}>
                <div className={isFixed ? 'header-video-col col-md-5 col-xl-4' : null}>
                  <Video
                    url={props.url}
                    playing={playing}
                    onPlay={onPlay}
                    {...(thumbnail ? {thumbnail} : {})}
                    label={label}
                  />
                </div>
                {isFixed ? (
                  <Col md="7" xl="6" className="py-2">
                    <div className="header-video-cta text-left">
                      <div className="d-none d-md-inline">{headline}</div>

                      {props.optin ? <OptinModalTrigger entry={props.entry} /> : null}

                      {props.sales ? (
                        <CheckoutButton sku={props.sku} price={props.price} slug={props.slug}>
                          {salesBtnText}
                        </CheckoutButton>
                      ) : null}
                    </div>
                  </Col>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
