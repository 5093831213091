import React, {Component} from 'react';
const Context = React.createContext();

export default Context;

export class ModalProvider extends Component {
  open = () => this.setState({isOpen: true});
  close = () => this.setState({isOpen: false});
  toggle = () =>
    this.setState((prevState, props) => {
      return {isOpen: !prevState.isOpen};
    });

  state = {
    close: this.close,
    isOpen: false,
    open: this.open,
    toggle: this.toggle
  };

  render() {
    return <Context.Provider value={this.state}>{this.props.children}</Context.Provider>;
  }
}

export function useModal() {
  const context = React.useContext(Context);
  if (context === undefined) {
    throw new Error('useModal must be used within a ModalProvider');
  }
  return context;
}
