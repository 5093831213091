import React from 'react';
import {ModalBody, Button} from 'reactstrap';
import {useUserState} from 'contexts/user';
import {usePhaseState} from 'funnel-schedule/phase-context';

import Modal from 'components/Modal';
import {useModal} from 'contexts/modal';
import RegistrantInfoForm from 'components/RegistrationForm/components/RegistrantInfoForm';

export default function RegistrationModal({...props}) {
  const modalProps = useModal();
  const {email, registrationId} = useUserState();
  const {webinar: {id} = {}} = usePhaseState().phases || {};

  const onComplete = React.useCallback(() => {
    modalProps.toggle();
  }, []);

  return (
    <Modal {...props} isOpen={(!email && !registrationId) || !id} size="lg">
      <ModalBody className="px-lg-4 pt-0 pb-4">
        <RegistrantInfoForm id="modalOptInForm" />
      </ModalBody>
    </Modal>
  );
}
