import React from 'react';
import {Head} from 'react-static';

export default function PageMeta({
  url = 'https://thriving.foodrevolution.org/masterclass/',
  ogImage = 'https://cdn.foodrevolution.org/ppt/social/ppt-ogimage-v20191018.jpg',
  title = 'Join the Food For Health Masterclass with John Robbins',
  description = 'Unlock your unique body’s ultimate potential through this Masterclass with John Robbins where he talks about how you can make food the foundation of YOUR health with these 10 mighty plant-powered breakthroughs. This Masterclass is only available for a limited time, so register today!',
  ...props
}) {
  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:site" content="@afoodrevolution" />
      <meta property="twitter:image" content={ogImage} />
      <meta property="fb:app_id" content="731824260184014" />
      <meta property="og:site_name" content="Plant-Powered & Thriving" />
      <meta property="og:title" content={title} />
      <meta property="og:image" content={ogImage} />
      <meta property="og:type" content="website" />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={url} />
    </Head>
  );
}
