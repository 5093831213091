import React from 'react';
import {navigate} from '@reach/router';
import classNames from 'classnames';
import {Form, Formik, Field} from 'formik';
import InputMask from 'react-input-mask';
import * as Yup from 'yup';

import {Alert, Button, FormFeedback, FormGroup, Input, Label, CustomInput} from 'reactstrap';
import {useUserDispatch} from 'contexts/user';
import {submitForm} from 'utils/forms';
import './OptInFormInfographic.scss';
import track, {identify} from 'utils/track';

const {INFOGRAPHIC_OPTIN_FORM_ID} = process.env;
const {SITE_ID} = process.env;

const customInputForm = ({field, form: {touched, errors}, ...props}) => (
  <>
    <InputMask {...field} {...props}>
      {(inputProps) => (
        <Input {...inputProps} invalid={!!(touched[field.name] && errors[field.name])} onChange={field.onChange} />
      )}
    </InputMask>
    {touched[field.name] && errors[field.name] && <FormFeedback>{errors[field.name]}</FormFeedback>}
  </>
);

const SignupSchema = Yup.object().shape({
  firstName: Yup.string().required('Required'),
  email: Yup.string().email('Invalid email').required('Required')
});

function OptInFormInfographic({id, ...props}) {
  const userDispatch = useUserDispatch();
  return (
    <>
      <div className="optin-wrap mb-3">
        <Formik
          initialValues={{
            email: '',
            firstName: ''
          }}
          validationSchema={SignupSchema}
          onSubmit={(values, actions) => {
            return submitForm(values, INFOGRAPHIC_OPTIN_FORM_ID)
              .then(({data}) => {
                identify(data.proxy_data.user.uuid, {
                  uuid: data.proxy_data.user.uuid,
                  firstName: values.firstName,
                  email: values.email
                });
                return userDispatch({
                  type: 'SET_USER',
                  payload: {firstName: values.firstName, email: values.email, uuid: data.proxy_data.user.uuid}
                });
              })
              .then(() =>
                track('User Registered', {
                  property: SITE_ID,
                  entrypoint: 'Infographic',
                  label: `${SITE_ID} - Infographic`
                })
              )
              .then(() => props.onComplete())
              .then(() => navigate('/masterclass/?o=discover', {state: {fromInfographic: true}}))
              .catch((e) => {
                actions.setStatus({error: e});
                actions.setSubmitting(false);
              });
          }}
          render={({values, handleSubmit, isSubmitting, isValid, handleReset, status, ...props}) => (
            <Form>
              {status && status.error && <Alert color="danger">{status.error}</Alert>}
              <FormGroup>
                <Label for="firstName" hidden>
                  First Name
                </Label>
                <Field
                  name="firstName"
                  type={'text'}
                  component={customInputForm}
                  placeholder="What is your first name?"
                  bsSize="lg"
                />
              </FormGroup>
              <FormGroup>
                <Label for="email" hidden>
                  Email
                </Label>
                <Field
                  name="email"
                  type={'email'}
                  component={customInputForm}
                  placeholder="What is your best email?"
                  bsSize="lg"
                />
              </FormGroup>

              <Button className="btn-cta-block" color="cta" type="submit" disabled={isSubmitting || !isValid} block>
                {isSubmitting ? 'Loading...' : 'Get Your Free Poster!'}
              </Button>
            </Form>
          )}
        />
      </div>
      <p className="text-small text-left mb-0">
        By entering your information here, you are agreeing to receive messages from Food Revolution Network. Your email
        address will NEVER be shared or sold. You are always free to easily unsubscribe at any time. See our{' '}
        <a
          className={classNames(`text-underline link-${props.linkColor}`)}
          href="https://foodrevolution.org/privacy-policy/"
          target="_blank"
        >
          Privacy Policy
        </a>{' '}
        for more info.
      </p>
    </>
  );
}

OptInFormInfographic.defaultProps = {
  onComplete: () => {}
};

export default OptInFormInfographic;
