import React from 'react';
import {Button, Col, Container, Row} from 'reactstrap';
import FollowButtons from 'components/FollowButtons';
import classNames from 'classnames';
import './Footer.scss';

export default function Footer({
  className = 'background-black text-white',
  linkColor = 'white',
  adVariant = false,
  ...props
}) {
  return (
    <footer className={classNames('frn-footer wrapper', className)}>
      <Container>
        <Row className="d-flex align-items-end">
          <Col xs="12" xl="7" className="order-xl-2 text-center text-xl-right mb-3 mb-xl-0">
            <div className="mb-3">
              <FollowButtons alignment="justify-content-center justify-content-xl-end" noLike />
            </div>
            <nav className="nav-footer">
              <ul className="list-inline m-0">
                <li className="list-inline-item mr-3">
                  <a className="nav-footer-link link-white" href="https://foodrevolution.org/about" target="_blank">
                    About Us
                  </a>
                </li>
                <li className="list-inline-item mr-3">
                  <a
                    className="nav-footer-link link-white"
                    href="https://trees.org/sponsor/food-revolution-network/"
                    target="_blank"
                  >
                    Our Fruit Tree Planting Mission
                  </a>
                </li>
                <li className="list-inline-item mr-3">
                  <a
                    className="nav-footer-link link-white"
                    href="https://foodrevolution.org/terms-and-conditions"
                    target="_blank"
                  >
                    Terms &amp; Conditions
                  </a>
                </li>
                <li className="list-inline-item">
                  <a
                    className="nav-footer-link link-white"
                    href="https://foodrevolution.org/privacy-policy"
                    target="_blank"
                  >
                    Privacy Policy
                  </a>
                </li>
              </ul>
            </nav>
          </Col>
          <Col xs="12" xl="5" className="order-xl-1 text-center text-xl-left">
            <div className="d-flex flex-column flex-lg-row align-items-center mb-4">
              <div className="pr-lg-3">
                <Button
                  color="footerLogo"
                  href="https://join.foodrevolution.org/offerings/"
                  target="_blank"
                  disabled={adVariant ? false : true}
                >
                  <img
                    className={classNames(`footerLogoFRN`)}
                    src="https://cdn.foodrevolution.org/global/frn-logo-2024-trademarked-white.svg"
                    alt="Food Revolution Network logo"
                    width={258}
                    height={100}
                  />
                </Button>
              </div>
              <div>
                <img
                  src="https://cdn.foodrevolution.org/global/b-corp-logo-tagline-lockup-standards-white.svg"
                  alt="B Corp Logo with standards tagline"
                  width="180"
                  height="90"
                />
              </div>
            </div>
            <p className="mb-0">© {new Date().getFullYear()} - Food Revolution Network™ - All Rights Reserved</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="frn-footer-disclaimer">
              <p>
                At Food Revolution Network™ (FRN), our mission is healthy, ethical, and sustainable food for all.
                Information and resources shared by FRN are for informational purposes only and are not intended to
                diagnose, treat, or cure any type of disease or condition. Reviews and testimonials of specific diets
                and approaches to health represent individual experiences and FRN does not guarantee any specific
                results from these approaches for your specific situation.
              </p>
              <p>
                In support of our educational mission, we sometimes share resources offered by trusted partners. If you
                purchase products from one of these partners, FRN may at times receive a portion of the proceeds. These
                affiliations support our work and allow us to continue bringing you valuable, life-changing content.{' '}
                <a
                  className={classNames(`text-underline link-${linkColor}`)}
                  href="https://foodrevolution.org/terms-and-conditions/"
                  target="_blank"
                >
                  Here are our full terms & conditions.
                </a>{' '}
                To opt-out of ever having your info sold to 3rd parties under any circumstances,{' '}
                <a
                  className={classNames(`text-underline link-${linkColor}`)}
                  href="https://foodrevolution.org/do-not-sell-my-information/"
                  target="_blank"
                >
                  click here
                </a>
                . Manage your consent preferences in the{' '}
                <a id="ot-sdk-btn" className="ot-sdk-show-settings link-white">
                  Preference Center
                </a>
                .
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}
