import React, {useEffect} from 'react';

export default function useBeacon() {
  return useEffect(() => {
    const {HS_BEACON_ID} = process.env;
    if (!HS_BEACON_ID || !window.Beacon) return;
    window.Beacon('init', HS_BEACON_ID);
    return () => {
      window.Beacon('destroy');
    };
  }, []);
}
