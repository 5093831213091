const track = (event, properties, options, fn) => {
  typeof window !== 'undefined' &&
    window.analytics &&
    window.analytics.track &&
    window.analytics.track(event, properties, options, fn);
  typeof window !== 'undefined' &&
    window.rudderanalytics &&
    window.rudderanalytics.track &&
    window.rudderanalytics.track(event, properties, options, fn);
};
export const identify = (userId, traits, options, callback) => {
  typeof window !== 'undefined' &&
    window.analytics &&
    window.analytics.identify &&
    window.analytics.identify(userId, traits, options, callback);
  typeof window !== 'undefined' &&
    window.rudderanalytics &&
    window.rudderanalytics.identify &&
    window.rudderanalytics.identify(userId, traits, options, callback);
};
export const page = (...args) => {
  typeof window !== 'undefined' && window.analytics && window.analytics.page && window.analytics.page(...args);
  typeof window !== 'undefined' &&
    window.rudderanalytics &&
    window.rudderanalytics.page &&
    window.rudderanalytics.page(...args);
};
export default track;
