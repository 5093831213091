import React, {useCallback, useState} from 'react';
import {Button, Container, Col, Row} from 'reactstrap';
import {Link, animateScroll as scroll} from 'react-scroll';
import ABTest, {DebugMenu} from 'react-controlled-ab';
import {subscribeToCampaign} from 'react-controlled-ab/datalayers/vwo';
import classNames from 'classnames';
import Modal from 'components/Modal';
import useExitIntent from 'utils/useExitIntent';

export default function ModalExitFDA({version = 'fullPrice', ...props}) {
  const {isOpen, toggle} = useExitIntent(60000);

  return (
    <Modal className="modal-exit-intent modal-exit-intent-empowerment" isOpen={isOpen} toggle={toggle}>
      <Row className="text-center text-lg-left d-flex align-items-stretch m-0">
        <Col xs="12" lg="7" className="modal-exit-intent-copy order-lg-2 my-auto">
          {version === 'salesPrice' ? (
            <>
              <p>
                <b>Only available here:</b>
              </p>
              <p>
                Don’t miss your opportunity to kickstart your healthy and plant-powered life for only $7 (an{' '}
                <b>85% savings</b>)!
              </p>
            </>
          ) : (
            <>
              <p>Don’t miss out!</p>

              <p>
                <b>
                  Discover how the 14-Day Plant-Powered Accelerator can take your health to the next level — in just two
                  weeks.
                </b>
              </p>
            </>
          )}

          <Button
            className="btn-exit text-uppercase text-white text-h5"
            color="cta"
            onClick={toggle}
            block
            activeClass="active"
            spy={true}
            offset={-55}
            to="fda-cta-1"
            smooth={true}
            href="#"
            tag={Link}
          >
            Learn More
          </Button>
        </Col>
        <Col xs="12" lg="5" className="exit-modal-image-col order-lg-1">
          <div className="exit-modal-image background-image-1193121375" />
        </Col>
      </Row>
    </Modal>
  );
}
