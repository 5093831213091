import React, {useEffect} from 'react';
import {navigate} from '@reach/router';
import MasterclassIndex from '../masterclass/index';

export default function () {
  useEffect(() => {
    navigate('/a-m/masterclass/' + location.search);
  }, []);
  return <MasterclassIndex noFixedVideo noExitIntent noShare variant="ad" />;
}
