import React from 'react';
import {FormFeedback, CustomInput} from 'reactstrap';

export default function({field, form: {touched, errors}, ...props}) {
  return (
    <>
      <CustomInput invalid={!!(touched[field.name] && errors[field.name])} {...field} {...props} />
      {touched[field.name] && errors[field.name] && <FormFeedback>{errors[field.name]}</FormFeedback>}
    </>
  );
}
