import React, {useCallback, useState} from 'react';
import {Button, Container, Col, Row} from 'reactstrap';

import Modal from 'components/Modal';
import ModalContext from 'contexts/modal';
import {Link, animateScroll as scroll} from 'react-scroll';
import useExitIntent from 'utils/useExitIntent';

export default function ModalExitAlumni({children, ...props}) {
  const {isOpen, toggle} = useExitIntent(60000);

  return (
    <Modal className="modal-exit-intent modal-exit-intent-alumni" isOpen={isOpen} toggle={toggle}>
      <Row className="text-center text-lg-left d-flex align-items-stretch m-0">
        <Col xs="12" lg="7" className="modal-exit-intent-copy order-lg-2 my-auto">
          <h3 className="section-heading">Don't miss out!</h3>
          <h6 className="text-400 my-4">Get your special Plant-Powered & Thriving Alumni discount!</h6>
          <h6 className="text-green m-0">
            Become a WHOLE Life Club Member <br className="d-none d-sm-block" />
            for just $127/year, now.
          </h6>

          <Button
            className="btn-exit text-uppercase text-white text-h5"
            color="cta"
            onClick={toggle}
            block
            activeClass="active"
            spy={true}
            offset={-55}
            to="hbc-alumni-cta-1"
            smooth={true}
            href="#"
            tag={Link}
          >
            Find Out How
          </Button>
        </Col>
        <Col xs="12" lg="5" className="exit-modal-image-col order-lg-1">
          <div className="exit-modal-image background-image-wlc-exit-sales" />
        </Col>
      </Row>
    </Modal>
  );
}
