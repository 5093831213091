import React from 'react';
import FacebookLikeButton from 'components/FacebookLikeButton';
import {faFacebookF} from '@fortawesome/free-brands-svg-icons/faFacebookF';
import {faXTwitter} from '@fortawesome/free-brands-svg-icons/faXTwitter';
import {faPinterestP} from '@fortawesome/free-brands-svg-icons/faPinterestP';
import {faInstagram} from '@fortawesome/free-brands-svg-icons/faInstagram';
import {faYoutube} from '@fortawesome/free-brands-svg-icons/faYoutube';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import './FollowButtons.scss';

export default function FollowButtons({
  alignment = 'justify-content-center justify-content-lg-end',
  className = 'social-follow-link',
  followText = false,
  ...props
}) {
  return (
    <>
      {followText ? (
        <div className="social-follow d-flex align-items-center justify-content-center">
          <div className="social-follow-text mb-2 mb-sm-0 mr-sm-2">Follow us on social:</div>
          <div className="d-flex align-items-center justify-content-center">
            <FollowButtonsList {...props} />
          </div>
        </div>
      ) : (
        <div className={classNames('d-flex align-items-center', alignment, className)}>
          <FollowButtonsList {...props} />
        </div>
      )}
    </>
  );
}

export function FollowButtonsList({
  fb = 'https://www.facebook.com/foodrevolutionnetwork',
  insta = 'https://www.instagram.com/foodrevolutionnetwork',
  twitter = 'https://www.twitter.com/afoodrevolution',
  pinterest = 'https://www.pinterest.com/afoodrevolution',
  youtube = 'https://www.youtube.com/foodrevolutionnetwork',
  ...props
}) {
  return (
    <>
      {props.noLike ? (
        <a className="social-button" href={fb} target="_blank">
          <div className="btn btn-social btn-social-facebook">
            <FontAwesomeIcon className="social-button-icon" icon={faFacebookF} />
          </div>
        </a>
      ) : (
        <div className="social-button social-button-facebook">
          <FacebookLikeButton url={fb} />
        </div>
      )}

      <a className="social-button" href={twitter} target="_blank">
        <div className="btn btn-social btn-social-twitter">
          <FontAwesomeIcon className="social-button-icon" icon={faXTwitter} />
        </div>
      </a>

      <a className="social-button" href={insta} target="_blank">
        <div className="btn btn-social btn-social-instagram">
          <FontAwesomeIcon className="social-button-icon" icon={faInstagram} />
        </div>
      </a>

      <a className="social-button" href={pinterest} target="_blank">
        <div className="btn btn-social btn-social-pinterest">
          <FontAwesomeIcon className="social-button-icon" icon={faPinterestP} />
        </div>
      </a>

      <a className="social-button" href={youtube} target="_blank">
        <div className="btn btn-social btn-social-youtube">
          <FontAwesomeIcon className="social-button-icon" icon={faYoutube} />
        </div>
      </a>
    </>
  );
}
