import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {Form, Formik, Field} from 'formik';
import {DateTime} from 'luxon';
import {Alert, Button, FormGroup} from 'reactstrap';
import * as Yup from 'yup';

import {useUserDispatch, useUserState} from 'contexts/user';
import {submitForm} from 'utils/forms';
import CustomSelectField from 'components/OptInForm/components/CustomSelectField';
import {usePhaseState, usePhaseDispatch} from 'funnel-schedule/phase-context';

const {SF_API_URL} = process.env;
const {REREG_FORM_ID} = process.env;
const {SITE_ID} = process.env;

const ReregSchema = Yup.object().shape({
  webinar_time: Yup.number().required('Required')
});

export default function ({id, onComplete = () => {}, buttonTxt = 'Continue'}, fromInfographic = false, ...props) {
  const {email, registrationId} = useUserState();
  const userDispatch = useUserDispatch();
  const phaseDispatch = usePhaseDispatch();

  const {
    phases: {default: schedule = {}}
  } = usePhaseState();
  const [timeSlots, setTimeSlots] = useState({loaded: false, data: []});
  useEffect(() => {
    const {webinar_id} = schedule;
    webinar_id &&
      axios.get(`${SF_API_URL}/webinar/${webinar_id}/schedule`).then(({data}) => setTimeSlots({loaded: true, data}));
  }, [schedule]);

  return (
    <Formik
      initialValues={{
        webinar_time: ''
      }}
      validationSchema={ReregSchema}
      onSubmit={(values, actions) => {
        return submitForm({...values, email}, REREG_FORM_ID)
           .then(({data: {proxy_data}}) =>
             userDispatch({
               type: 'SET_USER',
               payload: {
                 email: email,
                 registrationId: proxy_data.registrationId
               }
             })
           )
          .then(() =>
            axios.get(`${SF_API_URL}/site/${SITE_ID}/state/`, {
              params: {
                email,
                registrationId
              }
            })
          )
          .then(({data}) =>
            phaseDispatch({
              type: 'SET_PHASE',
              payload: data
            })
          )
          .then(() => onComplete())
          .catch((e) => {
            actions.setStatus({error: e});
            actions.setSubmitting(false);
          });
      }}
      render={({values, handleSubmit, isSubmitting, isValid, status}) => (
        <Form onSubmit={handleSubmit}>
          {status && status.error && <Alert color="danger">{status.error}</Alert>}
          <FormGroup>
            <p className="text-center">
              <strong>Choose your preferred {fromInfographic ? null : 'new '}session below.</strong>
            </p>
            <Field
              type="select"
              name="webinar_time"
              component={CustomSelectField}
              disabled={!timeSlots.loaded || !timeSlots.data.length}
              bsSize="lg"
            >
              {timeSlots.loaded ? (
                timeSlots.data.length ? (
                  [
                    <option value="" key={0} disabled selected>
                      Select a time...
                    </option>
                  ].concat(
                    timeSlots.data.sort().map((v) => (
                      <option value={v} key={v}>
                        {DateTime.fromSeconds(v).toLocaleString({
                          month: 'short',
                          day: '2-digit',
                          hour: 'numeric',
                          minute: '2-digit',
                          timeZoneName: 'short',
                          weekday: 'short'
                        })}
                      </option>
                    ))
                  )
                ) : (
                  <option value="">No time slots currently available.</option>
                )
              ) : (
                <option value="">Loading...</option>
              )}
            </Field>
          </FormGroup>

          <Button
            className="text-uppercase text-h4 text-condensed text-800"
            color="cta"
            type="submit"
            disabled={isSubmitting || !isValid}
            block
          >
            {isSubmitting ? 'Loading...' : buttonTxt}
          </Button>
        </Form>
      )}
    />
  );
}
