import React, {useCallback, useEffect, useState} from 'react';
import {Container, Col, Row} from 'reactstrap';
import axios from 'axios';
import {usePhaseState, usePhaseDispatch} from 'funnel-schedule/phase-context';
import CountdownTimer from 'components/CountdownTimer';
import WebinarVideo from 'components/WebinarVideo';
import Video from 'components/Video';
import {useUserState} from 'contexts/user';
import MasterclassReregisterForm from 'components/MasterclassReregisterForm';

const {SF_API_URL} = process.env;
const {SITE_ID} = process.env;

export default function Webinar({context = 'webinar', noun = 'Webinar', label, phaseOverride = null, ...props}) {
  const {registrationId, bucket} = useUserState();
  const phases = usePhaseState().phases || {};
  const dispatch = usePhaseDispatch();
  const schedule = phases[context] || {};
  const {attended, events, phase, pos, time, url: webinar_url, id: webinar_id} = schedule;

  const webinarPhase = phaseOverride ? phaseOverride : phase;

  useEffect(() => {
    if (phase !== 'during' || attended || !(webinar_id && registrationId && time)) return;
    axios.post(`${SF_API_URL}/webinar/${webinar_id}/event/`, {
      type: 'attend',
      registrationId,
      time
    });
    dispatch({
      type: 'UPDATE_PHASE',
      payload: {
        context,
        data: {
          attended: true
        }
      }
    });
  }, [schedule, registrationId]);

  useEffect(() => {
    pos < 0 &&
      setTimeout(
        () =>
          registrationId &&
          axios
            .get(`${SF_API_URL}/site/${SITE_ID}/state/`, {
              params: {
                registrationId
              }
            })
            .then(({data}) =>
              dispatch({
                type: 'SET_PHASE',
                payload: {...data, pos: 0}
              })
            ),
        pos * -1000
      );
  }, [pos, registrationId]);

  const onWebinarEnd = useCallback(
    (e) => {
      dispatch({
        type: 'UPDATE_PHASE',
        payload: {
          context,
          data: {
            phase: 'replay',
            pos: 0
          }
        }
      });
    },
    [schedule]
  );

  const onEventFired = useCallback(
    (name) => {
      if ((schedule.firedEvents || []).find((e) => e === name)) return;
      axios.post(`${SF_API_URL}/webinar/${webinar_id}/event/`, {
        type: 'custom',
        name,
        registrationId,
        time
      });
      dispatch({
        type: 'ADD_PHASE_EVENT',
        payload: {
          context,
          event: name
        }
      });
    },
    [schedule]
  );

  switch (webinarPhase) {
    case 'before':
      return (
        <div className="webinar-before text-center">
          <div className="webinar-before-heading background-black-90 text-white p-3">
            <h4 className="section-only-heading">Your {noun} starts in:</h4>
          </div>
          <CountdownTimer
            className="webinar-before-countdown countdown-webinar"
            time={pos * -1000 + new Date().getTime()}
          />
        </div>
      );
    case 'during':
      return (
        <>
          <WebinarVideo
            {...schedule}
            url={webinar_url}
            onEnded={onWebinarEnd}
            onEventFired={onEventFired}
            noun={noun}
            controls={false}
            events={events}
            label={label}
            defaultTextTrack="en"
          />
        </>
      );
    case 'replay':
    case 'after':
      return (
        <>
          <div className="background-light-gray p-3">
            <h5 className="section-only-heading text-center">Your {noun} is now on replay.</h5>
          </div>
          <Video url={webinar_url} events={events} label={label} />
        </>
      );
    case 'missed':
      return (
        <div className="background-light-gray p-4 p-lg-5">
          <h4 className="section-heading text-center">Your {noun} has ended but you can book a new time now!</h4>
          <MasterclassReregisterForm />
        </div>
      );
    default:
      return null;
  }
}
